import React, { memo, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import config from '../../config';
import {
  Page,
  LayoutSingleColumn,
  LayoutWrapperTopbar,
  LayoutWrapperMain,
  LayoutWrapperFooter,
  Footer,
  FooterBottomMenu,
  Modal,
  LeftBar,
  HorizontalScroll,
} from 'components';
import classNames from 'classnames';
import debounce from 'lodash/debounce';
import { FormattedMessage, injectIntl } from '../../util/reactIntl';
import { withViewport } from '../../util/contextHelpers';
import { withRouter } from 'react-router-dom';

import { MAX_MOBILE_SCREEN_WIDTH } from './JobsBoard.data';
import { city, cityEducation } from 'marketplace-custom-config';
import { ShareForm } from 'forms';
import twitterImage from '../../assets/probuddyThumbnail-twitter-600X314.png';
import facebookImage from '../../assets/probuddyThumbnail-facebook-1200X630.png';
import twitterImageEdu from '../../assets/twitterThumbnail-Edu-600X310.png';
import facebookImageEdu from '../../assets/facebookThumnail-Edu-1200X630.png';
import css from './JobsBoard.css';
import { compose } from 'redux';
import TopbarContainer from 'containers/TopbarContainer/TopbarContainer';
import { onGetJobsBoard } from './JobsBoard.duck';
import JobsBoardForm from './jobsboardComponents/JobsBoardForm';
import { parse } from 'util/urlHelpers';
import TopFloatingMessage from 'components/TopFloatingMessage/TopFloatingMessage';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { getBannerImg, getQueAnsList } from 'components/GetQuoteModal/GetQuoteModal.data';
import JobsBoardLeadModel from './jobsboardComponents/JobsBoardLeadModel';
import { changingActivefooterTab } from 'ducks/user.duck';

const DEBOUNCE_WAIT_TIME = 600;
const initSearchValue = {
  keywords: '',
  pub_listing_type: 'jobsboard',
  pub_category: '',
  location: '',
  pub_sub_category: '',
  pub_is_active: null,
  page: 1,
};
const JobsBoardComponent = props => {
  // props
  const { viewport, history, location, params, intl, isEducationSite, isTennisSite } = props;
  const { pathname, search, state } = location;

  const searchParams = parse(search);

  // for Quote Model

  // search state
  const [jobsSearch, setJobsSearch] = useState({
    ...initSearchValue,
    // search
    keywords: searchParams?.q || '',
    pub_sub_category: searchParams?.category?.split(',') || '',
    location: searchParams?.city || '',
    pub_is_active: searchParams?.active || null,
    pub_category: isEducationSite ? 'education' : isTennisSite ? 'tennis' : 'sports',
  });

  const [activeFooterTab, setActiveFooterTab] = useState('jobsBoard');
  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [showQuote, setShowQuote] = useState(false);
  const [quotesModalOpen, setQuotesModalOpen] = useState(false);
  const [leadModel, setLeadModel] = useState(false);

  const dispatch = useDispatch();

  const { currentUser } = useSelector(state => state.user);
  const { jobsBoardListing, loading, jobsPagination } = useSelector(state => state.JobsBoard);

  const getListingData = async searchParams => {
    dispatch(onGetJobsBoard(searchParams));
  };

  useEffect(() => {
    dispatch(changingActivefooterTab('jobsBoard'));
    if (typeof window !== 'undefined') {
      window.onscroll = () => {
        setShowQuote(window.pageYOffset > 500);
      };
    }
  }, []);

  useEffect(() => {
    const searchValue = {
      ...jobsSearch,
      pub_sub_category:
        Array.isArray(jobsSearch.pub_sub_category) &&
        jobsSearch.pub_sub_category?.length > 0 &&
        !jobsSearch.pub_sub_category.includes('select-all')
          ? jobsSearch.pub_sub_category
          : '',
    };

    getListingData(searchValue);
  }, [
    jobsSearch.keywords,
    jobsSearch.pub_sub_category,
    jobsSearch.location,
    jobsSearch.pub_is_active,
    jobsSearch.page,
  ]);

  const url = typeof window !== 'undefined' && window.location.href;
  const JobsBoard = config.custom.jobsBoard;
  const cityData = isEducationSite ? cityEducation : city;

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  const siteTitle = isEducationSite ? 'Probuddy Education' : config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'JobsBoard.schemaTitle' }, { siteTitle });
  const schemaDescription = intl.formatMessage({ id: 'JobsBoard.schemaDescription' });

  const schemaKeywords = intl.formatMessage({ id: 'JobsBoard.schemaKeywords' });

  const canonicalRootURL = config.canonicalRootURL;

  // Functions

  const makeUrl = () => {
    const subCategories =
      Array.isArray(jobsSearch.pub_sub_category) &&
      jobsSearch.pub_sub_category?.length > 0 &&
      !jobsSearch.pub_sub_category.includes('select-all')
        ? jobsSearch.pub_sub_category.join(',')
        : '';
    const keywords = jobsSearch.keywords;
    const city = jobsSearch.location;
    const active = jobsSearch.pub_is_active;

    let pushStr = '?';
    if (city) pushStr += `city=${city}`;

    if (pushStr.length > 1 && subCategories) pushStr += '&';
    if (subCategories) pushStr += `category=${subCategories}`;

    if (pushStr.length > 1 && keywords) pushStr += '&';
    if (keywords) pushStr += `q=${keywords}`;

    if (pushStr.length > 1 && typeof active === 'boolean') pushStr += '&';
    if (typeof active === 'boolean') pushStr += `active=${active}`;

    history.push(pathname + pushStr);
  };

  useEffect(() => {
    makeUrl();
  }, [
    jobsSearch.keywords,
    jobsSearch.pub_sub_category,
    jobsSearch.location,
    jobsSearch.pub_is_active,
    jobsSearch.page,
  ]);

  // city search functions
  const setSelectedLocation = city => {
    if (jobsSearch.location !== city) {
      setJobsSearch(p => ({ ...p, location: city }));
    } else {
      setJobsSearch(p => ({ ...p, location: '' }));
    }
  };
  const handlePage = page => {
    if (jobsSearch.page !== page) {
      setJobsSearch(p => ({ ...p, page: page }));
    }
  };
  // serching functions
  const handleOnChange = value => {
    const searchValue = value.toLowerCase();

    setJobsSearch(p => ({ ...p, keywords: searchValue }));
  };
  const handleSwitch = value => {
    if (jobsSearch.pub_is_active !== value) {
      setJobsSearch(p => ({ ...p, pub_is_active: value }));
    } else {
      setJobsSearch(p => ({ ...p, pub_is_active: null }));
    }
  };
  // checkBox search functions
  const handleOnCheck = event => {
    setJobsSearch(p => ({ ...p, pub_sub_category: event }));
  };

  const debounceKewwordSerch = debounce(handleOnChange, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  const debounceOnCheck = debounce(handleOnCheck, DEBOUNCE_WAIT_TIME, {
    leading: false,
    trailing: true,
  });

  return (
    <Page
      description={schemaDescription}
      title={schemaTitle}
      keywords={schemaKeywords}
      scrollingDisabled={false}
      schemaScript={false}
      twitterImages={[
        {
          name: 'twitter',
          url: isEducationSite
            ? `${canonicalRootURL}${twitterImageEdu}`
            : `${canonicalRootURL}${twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      facebookImages={[
        {
          name: 'facebook',
          url: isEducationSite
            ? `${canonicalRootURL}${facebookImageEdu}`
            : `${canonicalRootURL}${facebookImage}`,
          width: 600,
          height: 405,
        },
      ]}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar className={css.positionStatic}>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
        </LayoutWrapperTopbar>

        <LayoutWrapperMain>
          <div className={css.content}>
            {showQuote && (
              <TopFloatingMessage
                FormattedButtonText="CategoriesPage.quoteButtonText"
                className={css.floatingTopBar}
                titleMessage={`Let us find a ${isEducationSite ? 'Tutor' : 'Coach'} for you?`}
                // titleMessage={`Let us find a Coach for you?`}
                lableText="Tell us what you need"
                inputText="We aim to respond within 30 minutes"
                onClick={() => setQuotesModalOpen(true)}
                isEducationSite={isEducationSite}
              />
            )}
            <GetQuoteModal
              modalId={`jonsBoard.quotes`}
              open={quotesModalOpen}
              params={{}}
              intl={intl}
              banner={{
                img: getBannerImg('tennis'), // <---
                head: `Answer a couple questions to help us find you the best coach.`,
                desc: `With Probuddy, you can communicate with the best coaches in your city. We will match you with a program that is perfect for you within 24 hours.`,
              }}
              onModalClose={() => setQuotesModalOpen(false)}
              currentUser={!!currentUser?.attributes?.email ? currentUser : ''}
              isEducationSite={isEducationSite}
              isAuthenticated={!!currentUser?.attributes?.email}
              queOptionList={getQueAnsList('default')}
              category={true}
              mailAtbrs={{
                forName: `${isEducationSite ? 'Education ' : ''}sportName Category`,
                altUrl: `https://www.probuddy.io${isEducationSite ? '/education' : ''}/sportName`,
                postFixFromName: `${isEducationSite ? 'Education ' : ''}sportName Category`,
              }}
            />
            <JobsBoardLeadModel
              open={leadModel}
              onClose={() => setLeadModel(false)}
              userName={currentUser?.attributes?.profile?.displayName}
              userEmail={currentUser?.attributes?.email}
            />
            <section className={css.topSection}>
              <div className={css.topHeadContainer}>
                <div className={css.quoteWrapper}>
                  <h4>
                    <FormattedMessage id="JobsBoard.topHeadTitle" />
                  </h4>
                  <p>
                    <FormattedMessage id="JobsBoard.topHeadSubTitle" />
                  </p>
                  <div className={css.quoteWrapper}>
                    {/* <p><FormattedMessage id="CategoriesPage.topHeadSubTitle" /></p> */}

                    <div className={classNames(css.inputWrapper)}>
                      <div className={css.wrapperInput} onClick={() => setQuotesModalOpen(true)}>
                        <input disabled type="text" value="Tell Us what you are looking for" />
                        <button className={css.btnQuote}>
                          <FormattedMessage id="CategoriesPage.quoteButtonText" />
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* <div className={css.JobsLeadText}>
                    <p>
                      {/* <FormattedMessage id="JobsBoard.topHeadSubTitle" /> */}
                  {/* Get Paid $30 per Lead
                    </p>
                    <div className={css.btnGroup}>
                      <button
                        onClick={() => setLeadModel(true)}
                        className={classNames(css.Leadbtn, css.BtnBlue)}
                      >
                        Submit Lead
                      </button>
                      <button
                        onClick={() => history.push('/signup')}
                        className={classNames(css.Leadbtn, css.BtnOrange)}
                      >
                        Sign up to Jobs Board
                      </button>
                    </div>
                  </div> */}
                </div>
                <div className={css.heroImage}>
                  <div className={css.overlayBg}></div>

                  {/*
                     // if you have video enable this code 
                  {categoryData.heroVideo ? (
              <video className={css.heroVideo} controls playsinline autoPlay muted loop>
                <source
                  src={categoryData.heroVideo}
                  alt="How to find a Tennis Coaches"
                  title="How to find a Tennis Coaches"
                ></source>
              </video>
            ) : (
              <img src={categoryData.heroImage} alt="heroImage" />
            )} */}
                  <img src={JobsBoard.heroImg} alt="heroImage" />
                </div>
              </div>
            </section>
            {/* <div className={css.locationArea}>
              <div className={css.locationHeadingContainer}>
                <h3 className={css.locationTitle}>Choose Your City</h3>
              </div>
              <div className={css.locationHeader}>
                <HorizontalScroll
                  isSmallScrollArrow={false}
                  className={classNames(css.locationListContainer, {
                    [css.categoryListContainerWithoutArrow]:
                      typeof window !== 'undefined' ? window.screen.width <= 425 : true,
                  })}
                >
                  {cityData.map(city =>
                    !isEducationSite ? (
                      <div
                        key={city.key}
                        className={css.locationWrapperTop}
                        onClick={() => setSelectedLocation(city.key)}
                      >
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div
                          className={classNames(
                            css.locationTextWrap,
                            jobsSearch.location === city.key && css.selected
                          )}
                        >
                          {city.label}
                        </div>
                      </div>
                    ) : (
                      <div
                        key={city.key}
                        className={css.locationWrapperTop}
                        onClick={() => setSelectedLocation(city.key)}
                      >
                        <div className={css.locationImgWrap}>
                          <img src={city.cityImage} height="100px" width="100px" alt={city.label} />
                        </div>
                        <div
                          className={classNames(
                            css.locationTextWrap,
                            jobsSearch.location === city.key && css.selected
                          )}
                        >
                          {city.label}
                        </div>
                      </div>
                    )
                  )}
                </HorizontalScroll>
              </div>
            </div> */}
            <>
              {
                <JobsBoardForm
                  jobsBoardListing={jobsBoardListing}
                  jobsPagination={jobsPagination}
                  handlePage={handlePage}
                  intl={intl}
                  loading={loading}
                  isEducationSite={isEducationSite}
                  history={history}
                  handleSwitch={handleSwitch}
                  is_active={jobsSearch.pub_is_active}
                  prop={props}
                  currentUser={currentUser}
                  filterSearch={jobsSearch.keywords}
                  handleOnChange={debounceKewwordSerch}
                  handleOnCheck={debounceOnCheck}
                  checked={jobsSearch.pub_sub_category}
                />
              }
            </>
          </div>
        </LayoutWrapperMain>
        {!isMobileLayout ? (
          <LayoutWrapperFooter>
            <Footer isEducationSite={isEducationSite} />
          </LayoutWrapperFooter>
        ) : (
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
              <FooterBottomMenu
                changeActivieFooterTab={value => {
                  setActiveFooterTab(value);
                }}
                history={history}
                isEducationSite={isEducationSite}
              />
            )}
          </LayoutWrapperFooter>
        )}
      </LayoutSingleColumn>
      {/* <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('search')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={history}
          onShowEnquiryTab={onSearchProduct}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('search')}
        />
      </Modal> */}
      <Modal
        id="JobsBoard.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
    </Page>
  );
};

const JobsBoard = compose(withRouter, injectIntl, withViewport)(JobsBoardComponent);

export default memo(JobsBoard);
