import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { bool, object } from 'prop-types';
import { createResourceLocatorString } from 'util/routes';
import { createSlug, parse } from 'util/urlHelpers';
import moment from 'moment';
import FilterPopup from '../HeaderContainer/FilterPopUp';
import { withViewport } from '../../util/contextHelpers';

import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  LeftBar,
  Modal,
  SearchMap,
  FooterBottomMenu,
  Loading,
  AddStripeAccountModal,
  // IconSpinner,
  // NamedLink,
} from '../../components';
import { HeaderContainer, MobileHeaderContainer } from '../../containers';
import PhoneNumberModal from '../../components/PhoneNumberModal/PhoneNumberModal';
import config from '../../config';
import { getMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import twitterImage from '../../assets/probuddyThumbnail-twitter-600X314.png';
import facebookImage from '../../assets/probuddyThumbnail-facebook-1200X630.png';
import {
  loadData,
  showListing,
  queryPromotedListings,
  sendEnquiry,
  onSearchEnquiry,
} from './HomePage.duck';
import {
  onSearchSubCategory,
  onSearchLocation,
  onSearchListingTab,
} from '../HeaderContainer/HeaderContainer.duck';
import { routeConfiguration } from 'index';

import { IMAGES } from '../../util/imagesPath';

import {
  MAX_MOBILE_SCREEN_WIDTH,
  DEFAULT_DATE,
  defaultLocation,
  listingSwitches,
} from './HomePage.data';
import SubCategoriesFilter from './SubCategoriesFilter/SubCategoriesFilter';
// import CityFilter from './CityFilter/CityFilter';
import EnquiryFormHome from './EnquiryForm/EnquiryForm';
import ShowListings from './ShowListings/ShowListings';
import { city, subCategoriesFilterOptions } from 'marketplace-custom-config';
import { changingActivefooterTab } from 'ducks/user.duck';
import classNames from 'classnames';
import css from './HomePage.css';
import TopFloatingMessage from 'components/TopFloatingMessage/TopFloatingMessage';
import GetQuoteModal from 'components/GetQuoteModal/GetQuoteModal';
import { getBannerImg } from 'components/GetQuoteModal/GetQuoteModal.data';
import GetMatchActivityModal from 'components/GetMatchActivityModal/GetMatchActivityModal';
import GetRecommendationModal from 'components/GetRecommendationModal/GetRecommendationModal';

class HomePageComponent extends React.Component {
  constructor(props) {
    super(props);
    this.queryString = parse(this.props.location.search);
    this.state = {
      isOpen: false,
      isOpenSearchBar: false,
      showOpacity: '',
      currentTab: this.queryString.listing || props.searchListingTab || 'facility',
      currentTabObj: null,
      selectedDate: '',
      openLeftBar: false,
      showMap: false,
      queryParams: {},
      filterSearch: '',
      categorySearch: [],
      showFilter: false,
      disableFilterScroll: false,
      has_premium_membership: false,
      min: 0,
      max: 1000,
      activeFooterTab: 'search',
      selectedSubCategory: props.searchActivity,
      modalOpen: false,
      isScrolling: false,
      selectedLocation: props.searchLocation,
      date: DEFAULT_DATE,
      listingId: null,
      authorId: null,
      minTime: 0,
      maxTime: 22,
      selectedMinTime: 0,
      selectedMaxTime: 23,
      showTimePopup: false,
      selectedListing: this.queryString.listing || props.searchListingTab || 'facility',
      startPoint: 0,
      steps: 15,
      selectedAgeGroup: '',
      searchAllClasses: true,
      selectedLevelMode: '',
      selectedKidsAge: '',
      showQuotes: false,
      items: [],
      chargebeeData: {},
      page: props.pagination.page || 1,
      quotesModalOpen: false,
      activityModalOpen: false,
      recommendModalOpen: false,
      matchCardOpen: false,
      recommendCardOpen: false,
      isPlayerCardActive: true,
      isProCardActive: true,
      isMatchCardActive: false,
      isRewardCardActive: false,
    };

    this.onChange = this.onChange.bind(this);
    this.searchInput = null;
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleOnCheck = this.handleOnCheck.bind(this);

    this.onSearch = this.onSearch.bind(this);
    this.makeUrl = this.makeUrl.bind(this);
    this.handleHeaderSearchBar = this.handleHeaderSearchBar.bind(this);
    this.handleClassDateChange = this.handleClassDateChange.bind(this);
    this.onkeywordSearch = this.onkeywordSearch.bind(this);
    this.onChangeApplyTimeSearch = this.onChangeApplyTimeSearch.bind(this);
    this.onTimeChange = this.onTimeChange.bind(this);
    this.handleListingChange = this.handleListingChange.bind(this);

    this.setSelectedLocation = this.setSelectedLocation.bind(this);
    this.fetchMoreData = this.fetchMoreData.bind(this);
    this.fetchPremiumData = this.fetchPremiumData.bind(this);
    this.handleClassDateSearch = this.handleClassDateSearch.bind(this);
  }

  componentDidMount() {
    const queryString = parse(this.props.location.search);
    const citydata = city.find(val => {
      if (queryString?.city === val?.label) {
        return val;
      }
    });

    if (!this.state.selectedSubCategory) {
      const defaultCategory = 'padel tennis'; // Set the default category here
      const categoryData = subCategoriesFilterOptions.find(val => val.key === defaultCategory);

      if (categoryData) {
        this.onSubCategorySearch(categoryData);
      }
    }

    const address = queryString?.address || 'Singapore';
    const bounds = '1.4110994,103.9577613,1.2642103,103.6055395';
    const categorydata = subCategoriesFilterOptions.find(val => {
      if (queryString?.category === val?.key) {
        return val;
      }
    });
    this.setSelectedLocation(citydata);
    categorydata && this.onSubCategorySearch(categorydata);
    const selectedListing = queryString?.listing;
    this.setState(
      {
        selectedListing: selectedListing || 'facility',
        currentTab:
          selectedListing && selectedListing !== 'kids' && selectedListing !== 'currentlyPromoted'
            ? selectedListing
            : 'facility',
      },
      () => {
        this.makeUrl();
      }
    );
    this.props.queryPromotedListings({ pub_listing_type: selectedListing, address, bounds });
    this.props.onSearchListingTab(selectedListing);

    this.props.onchangingActivefooterTab && this.props.onchangingActivefooterTab('search');
    if (typeof window !== 'undefined') {
      localStorage.setItem('isEducationSite', false);
      window.onscroll = () => {
        let currentScrollPos = window.pageYOffset;
        this.setState({ showQuotes: currentScrollPos > 150 });
        let maxScroll = document.body.scrollHeight - (window.innerHeight - 100);
        if (currentScrollPos > 200 && currentScrollPos < maxScroll && !this.state.isScrolling) {
          this.setState({ isScrolling: true });
        } else if (currentScrollPos < 80 && this.state.isScrolling) {
          this.setState({ isScrolling: false });
        }
      };
    }
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.setItem('isEducationSite', false);
    }
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.setItem('isTennisSite', false);
    }

    if (this.props.currentUser && this.props.saveContactDetailsInProgress === false) {
      const {
        currentUser: {
          attributes: { profile: { publicData: { phoneNumber = '' } = {} } = {} } = {},
        } = {},
      } = this.props;
      if (this.props.currentUser && !phoneNumber) {
        this.setState({
          isOpen: true,
        });
      }
    }
    if (!this.props.currentUser?.attributes?.stripeConnected && this.props.currentUserHasListings) {
      !!this.props.currentUserListings?.length &&
        this.props.currentUserListings.forEach(l => {
          if (l.attributes?.publicData?.payment_method?.includes('card')) {
            this.setState({ isOpenStrip: true });
            return;
          }
        });
    }
    if (typeof window !== 'undefined' && !!window.sessionStorage) {
      window.sessionStorage.removeItem('privateSiteUrl');
    }
    if (localStorage.getItem('LandingFormData')) {
      this.props.history.push('/i/draft/00000000-0000-0000-0000-000000000000/new/description');
    }
    if (queryString && queryString?.listing_type) {
      this.setState({ currentTab: queryString.listing_type });
    }

    if (this.props.match.params.city_name) {
      const city_name = this.props.match.params.city_name;
      const getCityData = city.find(city => city_name === city.label);
      this.setState({ queryParams: 'company,Company,freelancer' });
      this.getSearchLocation(getCityData);
    }
    // if (this.state.currentTab === undefined || this.state.currentTab === '') {
    //   const randomTab = listing_type[_.random(0, listing_type.length)];
    //   this.setState({ ...this.state, currentTab: randomTab });
    //   this.props.queryPromotedListings({ pub_listing_type: randomTab });
    // }
  }

  componentDidUpdate(prevProps, prevState) {
    // if (this.state.currentTab === undefined || this.state.currentTab === '') {
    //   const randomTab = listing_type[_.random(0, listing_type.length)];
    //   this.setState({ ...this.state, currentTab: randomTab });
    //   this.props.queryPromotedListings({ pub_listing_type: randomTab });
    // }
    if (this.state.showOpacity) {
      document.body.classList.add(css.testScroll);
    } else {
      document.body.classList.remove(css.testScroll);
    }
    // if (!this.state.getElement && document.getElementById('react-autowhatever-1')) {
    //   this.setState({ getElement: true });
    // }
    if (prevProps.searchLocation !== this.props.searchLocation) {
      this.setState({ ...this.state, selectedLocation: this.props.searchLocation });
    }
    if (
      prevProps.promotedListings !== this.props.promotedListings &&
      this.props.promotedListings.length !== 0
    ) {
      // &&
      // this.state.startPoint === 0
      this.setState({
        items: this.props.promotedListings.slice(0, 15),
        startPoint: 0,
      });
      // this.setState({
      //   items: this.props.promotedListings,
      // });
    }

    if (
      this.props.currentUser?.attributes?.profile?.protectedData?.ChargebeeId &&
      Object.keys(this.state.chargebeeData).length === 0
    ) {
      this.setState({
        ...this.state,
        chargebeeData: this.props.currentUser?.attributes?.profile?.protectedData,
      });
    }
    if (this.props.currentUser === null && Object.keys(this.state.chargebeeData).length > 0) {
      this.setState({
        ...this.state,
        chargebeeData: {},
      });
    }
    const { selectedSubCategory, selectedLocation, selectedListing } = this.state;
    if (
      selectedSubCategory !== prevState?.selectedSubCategory ||
      selectedLocation !== prevState?.selectedLocation ||
      selectedListing !== prevState?.selectedListing
    ) {
      this.makeUrl();
    }
    // if (prevProps.promotedListings.length > 0 && prevProps.authorsListingsRefs.length === 0) {
    //   let authorRef = [];
    //   this.props.promotedListings.map(data => authorRef.push(data?.author?.id));
    //   this.props.onFetchAuthorListing(authorRef);
    // }
  }

  onTimeChange = value => {
    this.setState({ minTime: value[0], maxTime: value[1] });
  };

  setListingAuthor = (listingId, authorId) => {
    this.setState({ ...this.state, listingId: listingId, authorId: authorId });
  };

  makeUrl = () => {
    const { pathname } = this.props.location;
    const { selectedSubCategory, selectedLocation, selectedListing } = this.state;
    const city = selectedLocation?.search;
    const category = selectedSubCategory?.key;

    let pushStr = '?';
    // if (city) pushStr += `city=${city}`;

    if (pushStr.length > 1 && category) pushStr += '&';
    if (category) pushStr += `category=${category}`;

    if (pushStr.length > 1 && selectedListing) pushStr += '&';
    if (selectedListing) pushStr += `listing=${selectedListing}`;

    this.props.history.push(pathname + pushStr);
  };
  getSearchLocation(city) {
    const date = moment().format('YYYY-MM-DD');
    const selectedLocation = {
      predictions: [],
      search: city?.label,
      selectedPlace: {
        address: city?.label,
        bounds: city?.bounds,
      },
    };
    this.setState({ selectedLocation: selectedLocation });
    this.props.onSearchLocation(selectedLocation);
    this.onSearch({
      pub_listing_type: this.state.currentTab,
      address: selectedLocation ? selectedLocation?.search : null,
      bounds: selectedLocation ? city?.bounds : null,
      dates: this.state.currentTab && this.state.currentTab === 'class' ? `${date},${date}` : null,
      times: this.state.currentTab && this.state.currentTab === 'class' ? '00:00,23:59' : null,
    });
  }

  setSelectedLocation(city) {
    if (!city) {
      city = {
        label: 'Singapore',
        bounds: '1.4110994,103.9577613,1.2642103,103.6055395',
      };
    }

    const selectedLocation =
      this.state.selectedLocation?.search === city?.label ||
      this.state.selectedLocation?.label === city?.label
        ? null
        : {
            predictions: [],
            search: city.label,
            selectedPlace: {
              address: city.label,
              bounds: city.bounds,
            },
          };
    this.setState({ selectedLocation: selectedLocation });
    this.props.onSearchLocation(selectedLocation);
    // const date = moment().format('YYYY-MM-DD');
    // this.state.queryParams.pub_listing_type === 'enquiry'
    const searchParams = {
      address: selectedLocation ? selectedLocation?.search : null,
      bounds: selectedLocation ? city?.bounds : null,
    };
    this.onSearch(searchParams);
  }

  onChange(location) {
    if (location.selectedPlace) {
      this.props.onSubmit({ location });
      if (this.searchInput) {
        this.searchInput.blur();
      }
    }
  }
  handleOnChange(value) {
    this.setState({ filterSearch: value });

    this.props.onSearchEnquiry({
      pub_listing_type: 'enquiry',
      keywords: value,
    });
  }

  handleOnCheck(e) {
    const { value, checked } = e.target;
    let updatedValue = [];
    // Case 1 : The user checks the box
    if (checked) {
      updatedValue = [...this.state.categorySearch, value.toLowerCase()];
      this.setState({
        ...this.state,
        categorySearch: updatedValue,
      });
    }

    // Case 2  : The user unchecks the box
    else {
      updatedValue = this.state.categorySearch.filter(e => e !== value);
      this.setState({
        ...this.state,
        categorySearch: updatedValue,
      });
    }
    updatedValue.length === 0
      ? this.props.onSearchEnquiry({ pub_listing_type: 'enquiry' })
      : this.props.onSearchEnquiry({
          pub_listing_type: 'enquiry',
          pub_sub_category: updatedValue,
        });
  }

  toggleModal = () => {
    this.setState({
      isOpen: false,
    });
  };
  onOkay = () => {
    this.setState({
      isOpen: false,
    });
    this.props.history.push('/account/contact-details');
  };
  onOkayStripe = () => {
    this.setState({
      isOpenStrip: false,
    });
    this.props.history.push('/account/payment-methods');
  };

  handleRebookClick = tx => e => {
    const { history } = this.props;
    const title = tx.listing.attributes.title;
    const listingId = tx.listing.id.uuid;
    const url = createResourceLocatorString(
      'ListingPage',
      routeConfiguration(),
      { slug: createSlug(title), id: listingId },
      {}
    );
    history.push(url);
  };

  onManageDisableScrolling = (componentId, scrollingDisabled = true) => {};

  onSearch = searchquery => {
    const queryParam = {
      pub_listing_type: this.state.selectedListing || 'facility',
      ...this.state.queryParams,
      page: 1,
      ...searchquery,
      address: searchquery.address || 'Singapore',
      bounds: searchquery.bounds || '1.4110994,103.9577613,1.2642103,103.6055395',
      searchByDate:
        'searchByDate' in searchquery ? !searchquery.searchByDate : !this.state.searchAllClasses,
    };
    !searchquery.pub_level_group && delete queryParam.pub_level_group;
    !searchquery.pub_age_group && delete queryParam.pub_age_group;
    !searchquery.pub_level_mode && delete queryParam.pub_level_mode;
    this.setState({
      queryParams: queryParam,
      selectedListing: queryParam?.pub_kids_promotions
        ? queryParam?.pub_kids_promotions
        : queryParam?.pub_listing_type
        ? queryParam.pub_listing_type
        : 'facility',
      currentTab: queryParam?.pub_kids_promotions
        ? null
        : queryParam?.pub_listing_type
        ? queryParam?.pub_listing_type
        : 'facility',
    });
    this.props.queryPromotedListings(queryParam);
  };

  handleHeaderSearchBar = value => {
    this.setState({ isOpenSearchBar: value });
  };

  handleOpacityChange = value => {
    this.setState({ showOpacity: value });
  };

  handleClassDateChange = selectedDate => {
    const date = moment(selectedDate).format('YYYY-MM-DD');
    // const day = moment(selectedDate).format('dddd');
    this.setState({
      selectedDate: date,
      selectedMinTime: 0,
      selectedMaxTime: 23,
      minTime: 0,
      maxTime: 22,
    });
    this.onSearch({
      pub_listing_type: 'class',
      dates: `${date},${date}`,
      times: '00:00,23:59',
    });
  };

  onChangeApplyTimeSearch = () => {
    const { minTime, maxTime } = this.state;
    const date = this.state.selectedDate
      ? moment(this.state.selectedDate).format('YYYY-MM-DD')
      : moment().format('YYYY-MM-DD');
    // const day = this.state.selectedDate
    //   ? moment(this.state.selectedDate).format('dddd')
    //   : moment().format('dddd');
    this.setState({
      showTimePopup: false,
      selectedMinTime: minTime,
      selectedMaxTime: maxTime,
    });
    const searchParams = {
      pub_listing_type: 'class',
      dates: `${date},${date}`,
      times: `${this.state.selectedMinTime}:00,${this.state.selectedMaxTime}:00`,
    };
    this.onSearch(searchParams);
    // this.props.onSearch({
    //   // times: '00:00,23:59',
    // start: moment(date)
    //   .tz('Asia/Singapore')
    //   .hours(minTime)
    //   .toISOString(),
    //   end: moment(date)
    //     .tz('Asia/Singapore')
    //     .hours(maxTime)
    //     .toISOString(),
    // });
  };

  onSubCategorySearch = item => {
    const combineCategory = ['Adventure', 'Racket Sports', 'Wellness', 'Fitness Classes'];
    const isSelected = item?.label === this.state.selectedSubCategory?.label;

    this.setState({ selectedSubCategory: isSelected ? null : item });
    !combineCategory.includes(item.label) &&
      this.props.onSearchSubCategory(isSelected ? null : item);
    // : this.props.onSearchSubCategory();
    const searchParams = {
      pub_sub_category: isSelected ? null : item.key,
    };
    this.onSearch(searchParams);
  };

  onkeywordSearch = activity => {
    const searchParams = {
      pub_sub_category: activity?.key ? activity?.key : null,
      keywords: activity.title ? null : activity.key ? null : activity,
    };
    const defaultParams = {
      address: null,
      bounds: null,
      dates: null,
      times: null,
      price: null,
      pub_listing_type: null,
      pub_sub_category: null,
    };
    !!activity?.id &&
      this.setState({
        selectedListing: null,
        currentTab: null,
        selectedSubCategory: null,
        queryParams: defaultParams,
      });
    !!activity.id && this.props.onSearchListingTab(null);
    !!activity.id ? this.props.showListing(activity.id) : this.onSearch(searchParams);
  };

  onChangePrice = () => {
    this.handleOpacityChange(false);
    this.setState({ showFilter: false, disableFilterScroll: false });
    const searchParams = {
      price: `${this.state.min},${this.state.max}`,
    };
    this.onSearch(searchParams);
  };

  handleListingChange = item => {
    const selectedListing = this.state.selectedListing === item.id ? null : item.id;
    this.setState({
      selectedListing: selectedListing || 'facility',
      currentTab:
        selectedListing && selectedListing !== 'kids' && selectedListing !== 'currentlyPromoted'
          ? selectedListing
          : 'facility',
    });
    this.props.onSearchListingTab(selectedListing);
    if (item.id === 'kids' || item.id === 'currentlyPromoted') {
      const queryParams = {
        pub_kids_promotions: selectedListing,
        pub_listing_type: null,
        pub_category: 'adventure,wellness,sports',
        dates: null,
        times: null,
      };
      this.onSearch(queryParams);
    } else {
      const date = moment().format('YYYY-MM-DD');
      // .add(i, 'days');
      const searchParams = {
        pub_kids_promotions: null,
        pub_category: null,
        pub_listing_type: selectedListing ? selectedListing : 'facility',
        dates: selectedListing === 'class' ? `${date},${date}` : null,
        times: selectedListing === 'class' ? '00:00,23:59' : null,
      };
      this.onSearch(searchParams);
    }
  };
  handleAgeGroupChange = selectedItem => {
    this.setState(
      prevState => ({
        selectedAgeGroup: prevState.selectedAgeGroup === selectedItem.id ? null : selectedItem.id,
      }),
      () => {
        const searchParams =
          this.state.selectedAgeGroup || this.state.selectedLevelMode || this.state.selectedKidsAge
            ? {
                pub_age_group: this.state.selectedAgeGroup,
                pub_level_group: this.state.selectedLevelMode,
                pub_level_mode: this.state.selectedKidsAge,
              }
            : {};
        // Callback function after state is updated
        this.onSearch(searchParams);
      }
    );
    // You can perform additional actions or callbacks here if needed
  };

  handleClassDateSearch = e => {
    this.setState({ searchAllClasses: e });
    const date = moment().format('YYYY-MM-DD');
    const searchParams = {
      pub_kids_promotions: null,
      pub_category: null,
      pub_listing_type: this.state.selectedListing,
      dates: this.state.selectedListing === 'class' ? `${date},${date}` : null,
      times: this.state.selectedListing === 'class' ? '00:00,23:59' : null,
      searchByDate: e,
    };

    this.onSearch(searchParams);
  };

  handleLevelChange = selectedItem => {
    this.setState(
      prevState => ({
        selectedLevelMode: prevState.selectedLevelMode === selectedItem.id ? null : selectedItem.id,
      }),
      () => {
        const searchParams =
          this.state.selectedAgeGroup || this.state.selectedLevelMode || this.state.selectedKidsAge
            ? {
                pub_age_group: this.state.selectedAgeGroup,
                pub_level_group: this.state.selectedKidsAge,
                pub_level_mode: this.state.selectedLevelMode,
              }
            : {};
        // Callback function after state is updated
        this.onSearch(searchParams);
      }
    );
    // You can perform additional actions or callbacks here if needed
  };

  handleModeChange = selectedItem => {
    this.setState(
      prevState => ({
        selectedKidsAge: prevState.selectedKidsAge === selectedItem.id ? null : selectedItem.id,
      }),
      () => {
        const searchParams =
          this.state.selectedAgeGroup || this.state.selectedLevelMode || this.state.selectedKidsAge
            ? {
                pub_age_group: this.state.selectedAgeGroup,
                pub_level_group: this.state.selectedKidsAge,
                pub_level_mode: this.state.selectedLevelMode,
              }
            : {};
        // Callback function after state is updated
        this.onSearch(searchParams);
      }
    );
    // You can perform additional actions or callbacks here if needed
  };
  fetchPremiumData = e => {
    this.setState({ has_premium_membership: e });
    const date = moment().format('YYYY-MM-DD');
    const searchParams = {
      pub_kids_promotions: null,
      pub_category: null,
      pub_listing_type: this.state.selectedListing,
      dates: this.state.selectedListing === 'class' ? `${date},${date}` : null,
      times: this.state.selectedListing === 'class' ? '00:00,23:59' : null,
    };
    e
      ? this.onSearch({ ...searchParams, pub_has_premium_membership: e })
      : this.onSearch({ ...searchParams, pub_has_premium_membership: null });
  };

  fetchMoreData = () => {
    this.setState({ startPoint: this.state.startPoint + this.state.steps });
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(
          this.props.promotedListings?.slice(
            this.state.startPoint,
            this.state.startPoint + this.state.steps
          )
        ),
      });
    }, 1500);
  };
  render() {
    const {
      intl,
      scrollingDisabled,
      promotedListings,
      viewport,
      listingAllReviews,
      onSendEnquiry
    } = this.props;

    const siteTitle = config.siteTitle;
    const schemaTitle = intl.formatMessage({ id: 'HomePage.schemaTitle' }, { siteTitle });
    const schemaDescription = intl.formatMessage({ id: 'HomePage.schemaDescription' });
    const schemaKeywords = intl.formatMessage({ id: 'Page.schemaKeywords' });
    const schemaImage = `${config.canonicalRootURL}${IMAGES.facebookImage}`;
    const isMobileLayoutLeftbar = viewport.width < MAX_MOBILE_SCREEN_WIDTH;
    const isMobileLayout = !!viewport.width ? viewport.width < MAX_MOBILE_SCREEN_WIDTH : false;
    const { showMap, isScrolling } = this.state;
    // const country = getCountryCodes(config.locale);

    return (
      <Page
        className={css.mainPage}
        scrollingDisabled={scrollingDisabled}
        isBottomMenuRequire={false}
        contentType="website"
        description={schemaDescription}
        title={schemaTitle}
        keywords={schemaKeywords}
        hideCookies={isMobileLayout}
        facebookImages={[{ url: facebookImage, width: 1200, height: 630 }]}
        twitterImages={[
          {
            url: twitterImage,
            width: 600,
            height: 314,
          },
        ]}
        schema={{
          '@context': 'http://schema.org',
          '@type': 'WebPage',
          description: schemaDescription,
          name: schemaTitle,
          image: [schemaImage],
        }}
      >
        <LayoutSingleColumn>
          <LayoutWrapperTopbar className={css.layoutTopbar}>
            {this.state.activeFooterTab === 'create' ||
            this.state.activeFooterTab === 'leftbar' ||
            this.state.activeFooterTab === 'activitySearch' ||
            this.state.showFilter ? (
              <div className={css.overlayMain} />
            ) : null}
            {!!isMobileLayout ? (
              <MobileHeaderContainer
                showSearch={true}
                onSearch={this.onSearch}
                onTabChangeSearch={this.onSearch}
                resetPage={() => {}}
                handleOpacityChange={this.handleOpacityChange}
                onkeywordSearch={this.onkeywordSearch}
                isScrolling={
                  this.state.activeFooterTab === 'leftbar' ? false : this.state.isScrolling
                }
                handleHeaderSearchBar={this.handleHeaderSearchBar}
                onChangeActivity={value => this.setState({ selectedSubCategory: value })}
                searchModalOpen={value => this.setState({ modalOpen: value })}
                disableFilterScroll={value => this.setState({ disableFilterScroll: value })}
                showMap={isMobileLayout && this.state.showMap}
                currentTab={tab => {
                  this.setState({
                    currentTab: tab.id,
                    currentTabObj: tab,
                    selectedListing: tab.id,
                  });
                }}
              />
            ) : (
              <HeaderContainer
                onSearch={this.onSearch}
                selectedListing={this.state.selectedListing}
                handleHeaderSearchBar={this.handleHeaderSearchBar}
                onTabChangeSearch={this.onSearch}
                handleOpacityChange={this.handleOpacityChange}
                isMobileLayout={isMobileLayout}
                onkeywordSearch={this.onkeywordSearch}
                isScrolling={
                  this.state.activeFooterTab === 'leftbar' ? false : this.state.isScrolling
                }
                currentUser={this.props.currentUser}
                onChangeActivity={value => this.setState({ selectedSubCategory: value })}
                currentTab={tab => {
                  this.setState({
                    currentTab: tab?.id,
                    currentTabObj: tab,
                    selectedListing: tab.id,
                  });
                }}
                disableFilterScroll={value => this.setState({ disableFilterScroll: value })}
                showOpacity={this.state.showOpacity}
              />
            )}
          </LayoutWrapperTopbar>
          <LayoutWrapperMain>
            {(!!isMobileLayout ? !this.state.showMap : true) && (
              <SubCategoriesFilter
                showOpacity={this.state.showOpacity}
                showFilter={this.state.showFilter}
                activeFooterTab={this.state.activeFooterTab}
                selectedSubCategory={this.state.selectedSubCategory}
                onSubCategorySearch={this.onSubCategorySearch}
                options={subCategoriesFilterOptions}
              />
            )}

            <div
              className={classNames(css.main, {
                [css.mapMain]: showMap,
                [css.openMainHeaderMain]: this.state.isOpenSearchBar && this.state.showOpacity,
                [css.disableScroll]:
                  this.state.showOpacity ||
                  this.state.activeFooterTab === 'leftbar' ||
                  this.state.activeFooterTab === 'create' ||
                  this.state.activeFooterTab === 'activitySearch' ||
                  this.state.showFilter ||
                  this.state.modalOpen,
                [css.disableFilterScroll]: this.state.showOpacity && this.state.disableFilterScroll,
                [css.openModalDisableScrolling]: this.state.modalOpen,
              })}
            >
              {this.state.showQuotes && (
                <TopFloatingMessage
                  FormattedButtonText="CategoriesPage.quoteButtonText"
                  titleMessage={`Let us find a ${false ? 'Tutor' : 'Coach'} for you?`}
                  lableText="Tell us what you need"
                  inputText="We aim to respond within 30 minutes"
                  onClick={() => this.setState({ quotesModalOpen: true })}
                  isEducationSite={false}
                />
              )}
              <GetQuoteModal
                modalId={`HomePage.quotes`}
                open={this.state.quotesModalOpen}
                intl={intl}
                banner={{
                  img: getBannerImg('tennis'), // <---
                  head: `Answer a couple questions to help us find you the best coach.`,
                  desc: `With Probuddy, you can communicate with the best coaches in your city. We will match you with a program that is perfect for you within 24 hours.`,
                }}
                onModalClose={() => this.setState({ quotesModalOpen: false })}
                currentUser={
                  !!this.props.currentUser?.attributes?.email ? this.props.currentUser : ''
                }
                isEducationSite={false}
                isAuthenticated={!!this.props.currentUser?.attributes?.email}
                // queOptionList={getQueAnsList('default')}
                category={true}
                mailAtbrs={{
                  forName: `sportName Category`,
                  altUrl: `https://www.probuddy.io/sportName`,
                  postFixFromName: `sportName Category`,
                }}
              />
              {/* 
              //  floating Whatsapp messages and moblie what icon
              {isScrolling && (
                <div className={css.whatsappLinkHome}>
                  <a
                    href="https://wa.me/message/WHZFXEGQ6RL5H1"
                    onClick={() => {
                      whatsappLinkClickTrack();
                    }}
                  >
                    <img alt="whatsapp-link" src={WhatsappImg} />
                  </a>
                </div>
              )} */}
              <GetRecommendationModal
                modalId={`HomePage.recommend`}
                intl={intl}
                open={this.state.recommendModalOpen}
                banner={{
                  img:
                    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1651477194/probuddy/about-us-1056_pmbsd1.webp', // <---
                  head: `Recommend us a client and get rewarded on client's monthly transactions`,
                  desc: `With Probuddy Recommendations, we give you an opportunity to earn rewards`,
                }}
                isEducationSite={false}
                category={true}
                onModalClose={() => {
                  this.setState({ recommendModalOpen: false });
                }}
              />
              <GetMatchActivityModal
                modalId={`HomePage.activity`}
                intl={intl}
                open={this.state.activityModalOpen}
                banner={{
                  img:
                    'https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1651477194/probuddy/about-us-1056_pmbsd1.webp', // <---
                  head: `Answer a couple questions to help us find you the best match.`,
                  desc: `With Probuddy Player Community, you can communicate and play with the best matches.`,
                }}
                isEducationSite={false}
                category={true}
                onModalClose={() => this.setState({ activityModalOpen: false })}
              />
              {/* {!this.state.showMap && (
                <CityFilter
                  setSelectedLocation={this.setSelectedLocation}
                  selectedLocation={this.state.selectedLocation}
                  city={city}
                />
              )} */}

              {this.state.showOpacity ||
              this.state.activeFooterTab === 'create' ||
              this.state.activeFooterTab === 'leftbar' ? (
                <div className={css.overlayMain} />
              ) : null}
              {!!isMobileLayoutLeftbar ? (
                <></>
              ) : (
                <LeftBar
                  isMobileLayout={isMobileLayout}
                  history={this.props.history}
                  isOpenSearchBar={this.state.isOpenSearchBar}
                  showOpacity={this.state.showOpacity}
                  onShowEnquiryTab={this.onSearch}
                  isScrolling={this.state.isScrolling}
                  currentTab={tab => {
                    this.setState({ currentTab: tab, selectedListing: tab });
                  }}
                  onClose={() => this.setState({ activeFooterTab: 'search' })}
                />
              )}
              {!this.state.showMap && (
                <div
                  onClick={() =>
                    this.setState({
                      matchCardOpen: false,
                      activityModalOpen: false,
                      recommendModalOpen: false,
                      recommendCardOpen: false,
                    })
                  }
                  className={css.switchContainer}
                >
                  {listingSwitches.map(item => {
                    let label = item.label;
                    if (isMobileLayout && label === 'Players Community') {
                      label = 'Players';
                    }
                    if (isMobileLayout && label === 'Shop') {
                      return <></>;
                    }
                    return (
                      <div
                        key={item.id}
                        className={classNames(css.switch, {
                          [css.switchActive]: this.state.selectedListing === item.id,
                        })}
                        onClick={() => this.handleListingChange(item)}
                      >
                        <img className={css.switchimage} src={item.image} alt={label} />
                        <h4>{label}</h4>
                        {/* <Switch
                          uncheckedIcon={false}
                          checkedIcon={false}
                          // onChange={() => this.handleListingChange(item)}
                          checked={this.state.selectedListing === item.label}
                        /> */}
                      </div>
                    );
                  })}
                </div>
              )}
              {this.state.selectedListing === 'player-profile' && (
                <div className={css.perfectMatchMain}>
                  <h3 className={css.perfectMatchHead}>Find your Perfect Match</h3>
                  <div className={css.perfectMatchSection}>
                    <div className={css.perfectMatchWrap}>
                      <div
                        className={css.perfectMatchCard}
                        onClick={() =>
                          this.setState({
                            activityModalOpen: true,
                            matchCardOpen: false,
                          })
                        }
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502560/probuddy/MatchActivity/perfectMatch_taso9c.jpg"
                            alt="perfectMatch"
                          />
                          <div className={css.perfectMatchIcon}>
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502599/probuddy/MatchActivity/iconimageplayer_qxolfr.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>Create a Match or Activity</h3>
                          <p className={css.perfectMatchDetailText}>
                            For Players to connect with others to join there games
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          this.setState({
                            matchCardOpen: false,
                            isPlayerCardActive: true,
                            isMatchCardActive: false,
                          })
                        }
                        className={css.perfectMatchCard}
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502622/probuddy/MatchActivity/viewProfile_isxu9y.jpg"
                            alt="perfectMatch"
                          />
                          <div
                            className={classNames(css.perfectMatchIcon, {
                              [css.perfectMatchIconActive]: this.state.isPlayerCardActive,
                            })}
                          >
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502458/probuddy/MatchActivity/tennisRacket_hqktqt.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>View Player Profiles</h3>
                          <p className={css.perfectMatchDetailText}>
                            See all racket sports players around you and get in touch
                          </p>
                        </div>
                      </div>

                      <div
                        onClick={() =>
                          this.setState({
                            matchCardOpen: true,
                            isMatchCardActive: true,
                            isPlayerCardActive: false,
                          })
                        }
                        className={css.perfectMatchCard}
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502657/probuddy/MatchActivity/viewMatchesActivity_zswgwa.jpg"
                            alt="perfectMatch"
                          />
                          <div
                            className={classNames(css.perfectMatchIcon, {
                              [css.perfectMatchIconActive]: this.state.isMatchCardActive,
                            })}
                          >
                            {' '}
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502459/probuddy/MatchActivity/tennisBall_ksqozk.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>
                            View all Matches and Activities
                          </h3>
                          <p className={css.perfectMatchDetailText}>
                            Join games around you send a request and join the fun
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* {this.state.selectedListing === 'freelancer' && (
                <div className={css.perfectMatchMain}>
                  <h3 className={css.perfectMatchHead}>Recommend Clients and Get Rewarded</h3>
                  <div className={css.perfectMatchSection}>
                    <div className={css.perfectMatchWrap}>
                      <div
                        className={css.perfectMatchCard}
                        onClick={() =>
                          this.setState({
                            recommendModalOpen: true,
                            recommendCardOpen: false,
                          })
                        }
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1701766387/probuddy/static/images/HomePage/recommend_yd71vw.png"
                            alt="recommend"
                          />
                          <div className={css.perfectMatchIcon}>
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502599/probuddy/MatchActivity/iconimageplayer_qxolfr.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>Recommed us a Client</h3>
                          <p className={css.perfectMatchDetailText}>
                            Recommend a client and get rewarded on their bookings
                          </p>
                        </div>
                      </div>
                      <div
                        onClick={() =>
                          this.setState({
                            recommendCardOpen: false,
                            isProCardActive: true,
                            isRewardCardActive: false,
                          })
                        }
                        className={css.perfectMatchCard}
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1701767059/probuddy/static/images/HomePage/coaches_llm0dw.jpg"
                            alt="coaches"
                          />
                          <div
                            className={classNames(css.perfectMatchIcon, {
                              [css.perfectMatchIconActive]: this.state.isProCardActive,
                            })}
                          >
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502458/probuddy/MatchActivity/tennisRacket_hqktqt.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>View Pro's Profiles</h3>
                          <p className={css.perfectMatchDetailText}>
                            See all Pro's listing and get connected to our best coaches
                          </p>
                        </div>
                      </div>

                      <div
                        onClick={() =>
                          this.setState({
                            recommendCardOpen: true,
                            isRewardCardActive: true,
                            isProCardActive: false,
                          })
                        }
                        className={css.perfectMatchCard}
                      >
                        <div className={css.perfectMatchImgWrap}>
                          <img
                            src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1701766387/probuddy/static/images/HomePage/commisions_f4sgsi.jpg"
                            alt="perfectMatch"
                          />
                          <div
                            className={classNames(css.perfectMatchIcon, {
                              [css.perfectMatchIconActive]: this.state.isRewardCardActive,
                            })}
                          >
                            {' '}
                            <img
                              src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1699502459/probuddy/MatchActivity/tennisBall_ksqozk.png"
                              alt="iconimageplayer"
                            />
                          </div>
                        </div>
                        <div className={css.perfectMatchDetail}>
                          <h3 className={css.perfectMatchDetailHead}>View earned rewards</h3>
                          <p className={css.perfectMatchDetailText}>
                            See your rewards for clients you recommended
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )} */}
              {/* {this.state.selectedListing !== 'player-profile' && (
                <div className={css.premiumContainer}>
                  <div
                    // key={item.id}
                    className={classNames(css.switch_p, {
                      [css.switchChecked_p]: this.state.has_premium_membership !== false,
                      [css.switchUnchecked_p]: this.state.has_premium_membership === false,
                    })}
                  >
                    <h4>Display only Premium Members</h4>

                    <Switch
                      className={css.switchCalss}
                      uncheckedIcon={false}
                      checkedIcon={true}
                      onChange={e => this.fetchPremiumData(e)}
                      checked={this.state.has_premium_membership}
                    />
                  </div>
                </div>
              )} */}
              {this.props.gettingListings ? (
                <Loading />
              ) : (
                <>
                  {/* <InfiniteScroll
                    dataLength={this.state.items?.length}
                    next={this.fetchMoreData}
                    hasMore={this.state.items?.length < promotedListings?.length}
                    loader={
                      this.props.gettingListings === false && (
                        <div className={css.contentLoader}>
                          <IconSpinner />
                        </div>
                      )
                    }
                    // className={classNames(css.cardContainer)}
                  > */}
                  {/* <div> */}
                  {this.state.currentTab === 'enquiry' ? (
                    <EnquiryFormHome
                      promotedListings={promotedListings}
                      intl={intl}
                      prop={this.props}
                      currentUser={this.props.currentUser}
                      searchInputData={this.searchInput}
                      filterSearch={this.state.filterSearch}
                      handleOnChange={this.handleOnChange}
                      handleOnCheck={this.handleOnCheck}
                      onChange={this.onChange}
                      onSendEnquiry={onSendEnquiry}
                      subCategoriesFilterOptions={subCategoriesFilterOptions}
                    />
                  ) : (
                    // <EnquiryFormCategory />
                    !showMap && (
                      <ShowListings
                        viewport={this.props.viewport}
                        searchAllClasses={this.state.searchAllClasses}
                        handleClassDateSearch={this.handleClassDateSearch}
                        currentTab={this.state.currentTab}
                        chargebeeData={this.state.chargebeeData}
                        fetchMoreData={this.fetchMoreData}
                        gettingListings={this.props.gettingListings}
                        handleClassDateChange={this.handleClassDateChange}
                        intl={intl}
                        items={this.state.items}
                        listingAllReviews={listingAllReviews}
                        listingType={this.state.selectedListing}
                        maxTime={this.state.maxTime}
                        minTime={this.state.minTime}
                        open={this.state.matchCardOpen}
                        recommend={this.state.recommendCardOpen}
                        onChangeApplyTimeSearch={this.onChangeApplyTimeSearch}
                        onTimeChange={this.onTimeChange}
                        promotedListings={promotedListings}
                        showTimePopup={this.state.showTimePopup}
                        selectedDate={this.state.selectedDate}
                        selectedAgeGroup={this.state.selectedAgeGroup}
                        handleAgeGroupChange={this.handleAgeGroupChange}
                        selectedLevelMode={this.state.selectedLevelMode}
                        selectedKidsAge={this.state.selectedKidsAge}
                        handleModeChange={this.handleModeChange}
                        handleLevelChange={this.handleLevelChange}
                        selectedMaxTime={this.state.selectedMaxTime}
                        selectedMinTime={this.state.selectedMinTime}
                        setShowTimePopup={value => {
                          this.setState({ showTimePopup: value });
                        }}
                        // pagination={this.props.pagination}
                        // changePage={page => {
                        //   this.onSearch({ page: page });
                        //   this.setState({ page: page });
                        // }}
                        // page={this.state.page}
                      />
                    )
                    // this.state.items.length !== 0 &&
                    // this.state.items.map((pl, i) => {
                    //   return (
                    //     <ListingFlipCard
                    //       showCountry={true}
                    //       key={i}
                    //       pl={pl}
                    //       intl={intl}
                    //       isMobileLayout={isMobileLayout}
                    //       chargebeeData={this.state.chargebeeData}
                    //       listingType={this.state.selectedListing}
                    //       // sub_category={sportName.replaceAll('-', ' ')}
                    //     />
                    //   );
                    // })
                  )}

                  {showMap && (
                    <SearchMap
                      reusableContainerClassName={css.map}
                      // activeListingId={activeListingId}
                      bounds={
                        this.state.selectedLocation?.selectedPlace?.bounds
                          ? this.state.selectedLocation.selectedPlace.bounds
                          : defaultLocation.selectedPlace.bounds
                      }
                      center={
                        this.state.selectedLocation?.selectedPlace?.bounds
                          ? this.state.selectedLocation.selectedPlace.bounds
                          : defaultLocation.selectedPlace.bounds
                      }
                      isSearchMapOpenOnMobile={true}
                      listings={promotedListings || []}
                      onMapMoveEnd={() => {}}
                      onCloseAsModal={() => {}}
                      messages={intl.messages}
                    />
                  )}
                  {(!!isMobileLayout
                    ? showMap
                      ? true
                      : !this.state.isScrolling
                    : !this.state.isScrolling) &&
                    !(
                      (
                        this.state.activeFooterTab === 'create' ||
                        this.state.activeFooterTab === 'activitySearch' ||
                        this.state.activeFooterTab === 'leftbar'
                      )
                      // ) && (
                      //   <button
                      //     className={classNames(css.showMapBtn, {
                      //       [css.showMaponOpen]: showMap && isMobileLayout,
                      //     })}
                      //     onClick={() => {
                      //       this.setState({ showMap: !showMap });
                      //     }}
                      //   >
                      //     {showMap ? 'View list' : 'Map'}
                      //     <img src={showMap ? showListingIcon : showMapIcon} alt="show_map" />
                      //   </button>
                      // )}
                    )}
                  {/* </div> */}
                  {/* </InfiniteScroll> */}
                </>
              )}
            </div>
          </LayoutWrapperMain>
          <LayoutWrapperFooter
            className={classNames(css.layoutFooter, {
              [css.activeleftbarFooter]: this.state.activeFooterTab === 'leftbar',
              [css.createFooterTab]:
                this.state.activeFooterTab === 'create' ||
                this.state.activeFooterTab === 'activitySearch',
            })}
          >
            {!!isMobileLayout &&
              !(this.state.activeFooterTab === 'leftbar') &&
              !this.state.modalOpen && (
                <FooterBottomMenu
                  activeFooterTab={this.state.activeFooterTab}
                  changeActivieFooterTab={value => this.setState({ activeFooterTab: value })}
                  history={this.props.history}
                  isScrolling={this.state.isScrolling}
                />
              )}
          </LayoutWrapperFooter>
        </LayoutSingleColumn>
        <PhoneNumberModal
          id="ReviewOrderModal"
          isOpen={this.state.isOpen}
          onOkay={this.onOkay}
          onCloseModal={this.toggleModal}
          onManageDisableScrolling={this.onManageDisableScrolling}
          rootClassName={''}
        />
        <AddStripeAccountModal
          id="addStripeAccountModal"
          isOpen={this.state.isOpenStrip}
          onOkay={this.onOkayStripe}
          onCloseModal={() => {
            this.setState({ isOpenStrip: false });
          }}
          onManageDisableScrolling={this.onManageDisableScrolling}
        />
        <FilterPopup
          showFilter={this.state.showFilter}
          onClickOutside={() => {
            this.handleOpacityChange(false);
            this.setState({ showFilter: false, disableFilterScroll: false });
          }}
          onValueChange={value => {
            this.setState({ min: value[0], max: value[1] });
          }}
          isMobileLayout={isMobileLayout}
          max={this.state.max}
          min={this.state.min}
          applyPriceFilter={this.onChangePrice}
        />
        <Modal
          isOpen={!!(this.state.activeFooterTab === 'leftbar')}
          onClose={() => {
            this.setState({ activeFooterTab: 'search' });
            this.props.onchangingActivefooterTab('search');
          }}
          id="filterModal"
          onManageDisableScrolling={() => {}}
          className={css.modalLeftBar}
        >
          <LeftBar
            isMobileLayout={isMobileLayout}
            history={this.props.history}
            isOpenSearchBar={this.state.isOpenSearchBar}
            showOpacity={this.state.showOpacity}
            onShowEnquiryTab={this.onSearch}
            onLogoClick={() => {
              this.setState({ activeFooterTab: 'search' });
              this.props.onchangingActivefooterTab('search');
            }}
            onClose={() => {
              this.setState({ activeFooterTab: 'search' });
              this.props.onchangingActivefooterTab('search');
            }}
            currentTab={tab => {
              this.setState({ currentTab: tab, selectedListing: tab });
            }}
          />
        </Modal>
      </Page>
    );
  }
}

HomePageComponent.defaultProps = {
  currentUserListing: null,
  promotedListingRefs: [],
  promotedListingReviews: [],
};

HomePageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  currentUserListing: propTypes.listing,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser, currentUserListings, currentUserHasListings } = state.user;
  const { promotedListingRefs, gettingListings, pagination } = state.HomePage;
  // const { monthlyTimeSlots } = state.ListingPage;
  const { searchActivity, searchLocation, searchListingTab } = state.HeaderSection;
  const { saveContactDetailsInProgress } = state.ContactDetailsPage;
  const promotedListings = getMarketplaceEntities(state, promotedListingRefs);

  return {
    scrollingDisabled: isScrollingDisabled(state),
    promotedListings,
    currentUser,
    saveContactDetailsInProgress,
    searchActivity,
    searchLocation,
    gettingListings,
    currentUserListings,
    currentUserHasListings,
    searchListingTab,
    pagination
  };
};

const mapDispatchToProps = dispatch => ({
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  onSearchSubCategory: searchSubCategory => dispatch(onSearchSubCategory(searchSubCategory)),
  onSearchLocation: searchlocation => dispatch(onSearchLocation(searchlocation)),
  onSearchListingTab: searchListing => dispatch(onSearchListingTab(searchListing)),
  onSendEnquiry: (listingId, message) => dispatch(sendEnquiry(listingId, message)),
  onSearchEnquiry: data => dispatch(onSearchEnquiry(data)),
  onchangingActivefooterTab: value => dispatch(changingActivefooterTab(value)),
});

const HomePage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HomePageComponent);

HomePage.loadData = loadData;

export default HomePage;
