import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';
import { bool, object } from 'prop-types';
import { withViewport } from '../../util/contextHelpers';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import {
  LayoutSingleColumn,
  LayoutWrapperFooter,
  LayoutWrapperMain,
  LayoutWrapperTopbar,
  Page,
  NamedLink,
  LeftBar,
  Modal,
  FooterBottomMenu,
} from '../../components';
import { TopbarContainer } from '..';
import config from '../../config';
import { isScrollingDisabled } from '../../ducks/UI.duck';
import { injectIntl, intlShape } from '../../util/reactIntl';
import { propTypes } from '../../util/types';
import { searchListings, queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import classNames from 'classnames';
import { IMAGES } from '../../util/imagesPath';
import { logout } from '../../ducks/Auth.duck';
import {
  categoriesMediumOptionsConfig,
  educationCategoriesMediumOptionsConfig,
} from 'marketplace-custom-config';
import { changingActivefooterTab } from 'ducks/user.duck';
import CategoryDataContainer from './CategoryDataContainer';
import { queryPromotedListingsEdu } from 'containers/EducationPage/EducationPage.duck';
import { queryPromotedListingsTen } from 'containers/TennisPage/TennisPage.duck';
import { ShareForm } from 'forms';
import css from './CategoriesPage.css';
import {
  padelData,
  pickleballData,
  personalFitnessData,
  pilatesData,
  tennisData,
  yogaData,
} from './CategoriesPage.data';

const MAX_MOBILE_SCREEN_WIDTH = 1024;

const CategoriesPageComponent = props => {
  const {
    intl,
    scrollingDisabled,
    viewport,
    isEducationSite,
    onchangingActivefooterTab,
    isTennisSite,
  } = props;
  const siteTitle = config.siteTitle;
  const schemaTitle = intl.formatMessage({ id: 'CategoryPage.schemaTitle' }, { siteTitle });

  const schemaDescription = intl.formatMessage({ id: 'CategoryPage.schemaDescription' });
  const schemaImage = `${IMAGES.facebookImage}`;
  // const categoriesOptions = config.custom.categoriesOptionsConfig;
  const categoriesMediumOptions = isEducationSite
    ? educationCategoriesMediumOptionsConfig
    : [
        // ...categoriesMediumOptionsConfig,
        tennisData,
        // yogaData,
        // pilatesData,
        personalFitnessData,
        padelData,
        pickleballData,
      ];

  const url = typeof window !== 'undefined' && window.location.href;

  const [isOpenShareForm, setIsOpenShareForm] = useState(false);
  const [activeFooterTab, setActiveFooterTab] = useState('category');

  const isMobileLayout = !!viewport.width && viewport.width < MAX_MOBILE_SCREEN_WIDTH;

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        localStorage.setItem('isEducationSite', 'isTennisSite');
      }
    },
    [isEducationSite],
    [isTennisSite]
  );

  useEffect(() => {
    onchangingActivefooterTab('category');
  }, [onchangingActivefooterTab]);

  const onSearch = searchquery => {
    props.history.push(
      createResourceLocatorString(
        isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage',
        routeConfiguration(),
        {}
      )
    );
    setTimeout(() => {
      isEducationSite
        ? props.queryPromotedListingsEdu(searchquery)
        : isTennisSite
        ? props.queryPromotedListingsTen(searchquery)
        : props.queryPromotedListings(searchquery);
    }, 500);
  };

  return (
    <Page
      className={css.root}
      scrollingDisabled={scrollingDisabled}
      isBottomMenuRequire={false}
      contentType="website"
      description={schemaDescription}
      title={schemaTitle}
      facebookImages={[{ url: IMAGES.facebookImage, width: 1200, height: 600 }]}
      twitterImages={[
        {
          url: `${IMAGES.twitterImage}`,
          width: 600,
          height: 314,
        },
      ]}
      schema={{
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        description: schemaDescription,
        name: schemaTitle,
        image: [schemaImage],
      }}
    >
      <LayoutSingleColumn>
        <LayoutWrapperTopbar>
          <TopbarContainer
            setIsOpenShareForm={setIsOpenShareForm}
            isEducationSite={isEducationSite}
            // isTennisSite={isTennisSite}
          />
        </LayoutWrapperTopbar>
        <LayoutWrapperMain>
          <div className={classNames(css.main)}>
            {!isMobileLayout && (
              <LeftBar
                isMobileLayout={isMobileLayout}
                history={props.history}
                onShowEnquiryTab={onSearch}
                onLogoClick={() => setActiveFooterTab('search')}
                onClose={() => setActiveFooterTab('category')}
                isEducationSite={isEducationSite}
                // isTennisSite={isTennisSite}
              />
            )}
            <div>
              <div className={classNames(css.cardContainer, css.smallCardContainer)}>
                <div className={css.breadCrumbWrapper}>
                  <NamedLink
                    name={
                      isEducationSite ? 'EducationPage' : isTennisSite ? 'TennisPage' : 'HomePage'
                    }
                  >
                    Home
                  </NamedLink>
                  <span className={css.breadcrumbArrow}>&gt;</span>
                  <NamedLink name="EducationCategoriesPage" className={css.currentListingTypeName}>
                    Category
                  </NamedLink>
                </div>
                {!isMobileLayout || isEducationSite ? (
                  <div
                    className={classNames(
                      css.mediumCategoriesContainer,
                      isEducationSite && css.mediumCategoriesContainerEdu,
                      isTennisSite && css.mediumCategoriesContainerTen
                    )}
                  >
                    {categoriesMediumOptions.map(category => {
                      return (
                        <NamedLink
                          className={css.mediumCategoriesItem}
                          name={category.page}
                          params={category?.params}
                          style={{ backgroundColor: category.bgColor }}
                        >
                          <div className={css.mediumCategoriesItemTitle}>{category.title}</div>
                          <div className={css.mediumCategoriesItemImage}>
                            <img src={category.imgSrc} alt={' '} />
                          </div>
                        </NamedLink>
                      );
                    })}
                  </div>
                ) : (
                  <div className={css.boxWrapHeader}>
                    {/* <div className={css.boxWrapItem}>
                      {categoriesMediumOptions &&
                        categoriesMediumOptions?.length &&
                        categoriesMediumOptions[0] && (
                          <NamedLink
                            className={css.mediumCategoriesItem}
                            name={categoriesMediumOptions[0]?.page}
                            params={categoriesMediumOptions[0]?.params}
                          >
                            <div className={css.mediumCategoriesItemTitle}>
                              {categoriesMediumOptions[0]?.title}
                            </div>
                            <div className={css.mediumCategoriesItemImage}>
                              <img src={categoriesMediumOptions[0]?.imgSrc} alt={' '} />
                            </div>
                          </NamedLink>
                        )}
                    </div> */}
                    <div className={css.boxWrapItem}>
                      {categoriesMediumOptions &&
                        categoriesMediumOptions?.length &&
                        categoriesMediumOptions.map(category => {
                          return (
                            <NamedLink
                              className={css.headerItem}
                              name={category.page}
                              params={category?.params}
                            >
                              <div className={css.mediumCategoriesItemTitle}>{category.title}</div>
                              <div className={css.mediumCategoriesItemImage}>
                                <img src={category.imgSrc} alt={' '} />
                              </div>
                            </NamedLink>
                          );
                        })}
                    </div>
                  </div>
                )}
                {isEducationSite && (
                  <CategoryDataContainer
                    isEducationSite={isEducationSite}
                    // isTennisSite={isTennisSite}
                    isMobileLayout={isMobileLayout}
                  />
                )}
              </div>
            </div>
          </div>
        </LayoutWrapperMain>
        <LayoutWrapperFooter
          className={classNames(css.layoutFooter, {
            [css.activeleftbarFooter]: activeFooterTab === 'leftbar',
            [css.createFooterTab]:
              activeFooterTab === 'create' || activeFooterTab === 'activitySearch',
          })}
        >
          {!!isMobileLayout && !(activeFooterTab === 'leftbar') && (
            <FooterBottomMenu
              changeActivieFooterTab={value => setActiveFooterTab(value)}
              history={props.history}
              isEducationSite={isEducationSite}
              // isTennisSite={isTennisSite}
            />
          )}
        </LayoutWrapperFooter>
      </LayoutSingleColumn>
      <Modal
        isOpen={!!(activeFooterTab === 'leftbar')}
        onClose={() => setActiveFooterTab('category')}
        id="filterModal"
        onManageDisableScrolling={() => {}}
        className={css.modalLeftBar}
      >
        <LeftBar
          isMobileLayout={isMobileLayout}
          history={props.history}
          onShowEnquiryTab={onSearch}
          onLogoClick={() => setActiveFooterTab('search')}
          onClose={() => setActiveFooterTab('category')}
          isEducationSite={isEducationSite}
          // isTennisSite={isTennisSite}
        />
      </Modal>
      <Modal
        id="AllSportsPage.shareForm"
        className={css.shareFormModal}
        isOpen={isOpenShareForm}
        onClose={() => setIsOpenShareForm(false)}
        onManageDisableScrolling={() => {}}
      >
        <ShareForm url={url} />
      </Modal>
    </Page>
  );
};

CategoriesPageComponent.propTypes = {
  scrollingDisabled: bool.isRequired,
  // from withRouter
  history: object.isRequired,
  location: object.isRequired,
  currentUser: propTypes.currentUser,

  // from injectIntl
  intl: intlShape.isRequired,
};

const mapStateToProps = state => {
  const { currentUser } = state.user;

  return {
    scrollingDisabled: isScrollingDisabled(state),
    currentUser,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  queryPromotedListingsEdu: searchquery => dispatch(queryPromotedListingsEdu(searchquery)),
  queryPromotedListingsTen: searchquery => dispatch(queryPromotedListingsTen(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  onchangingActivefooterTab: value => dispatch(changingActivefooterTab(value)),
});

const CategoriesPage = compose(
  withRouter,
  withViewport,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(CategoriesPageComponent);

export default CategoriesPage;
