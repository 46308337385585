import React, { useState, useEffect, memo, useMemo } from 'react'
import SectionFacilities from 'containers/ListingPage/SectionFacilities'
import classNames from 'classnames'
import { useDispatch } from 'react-redux'
import {
  fetchAuthorClasses,
  fetchAuthorCourses,
  fetchCurrentListingUserReviews,
} from './ListingPage.duck'

import css from './CompanyPage/CompanyPage.css'
import moment from 'moment'

const MAX_MOBILE_SCREEN_WIDTH = 550
const DEFAULT_DATE = {
  start: moment()
    .set({ hour: 0, minute: 0, second: 0 })
    .toISOString(),
  end: moment()
    .set({ hour: 23, minute: 59, second: 59 })
    .toISOString(),
}

const CourtBookingCalander = props => {
  const {
    intl,
    currentListing,
    onFetchAuthorFacilities,
    authorFacilities,
    authorFacilitiesIsLoading,
    onFetchTimeSlots,
    facilityTimeslots,
    viewport,
    addBookingData,
    isEducationSite,
    bookedTimeslots,
  } = props

  const dispatch = useDispatch()
  const [maxDay, setMaxDay] = useState(1)
  const authorId = currentListing?.author?.id
  const authorAvailable = currentListing && currentListing.author;
  const currentAuthor = authorAvailable ? currentListing.author : null;

  useEffect(() => {
    authorId && dispatch(fetchCurrentListingUserReviews([authorId]))
  }, [authorId, dispatch])

  useEffect(() => {
    const authorId = currentListing?.author?.id?.uuid
    const DEFAULT_DATE = {
      start: moment()
        .set({ hour: 0, minute: 0, second: 0 })
        .toISOString(),
      end: moment()
        ?.add(7, 'd')
        ?.set({ hour: 23, minute: 59, second: 59 })
        ?.toISOString(),
    }

    if (isEducationSite) dispatch(fetchAuthorCourses(authorId, DEFAULT_DATE))
    else dispatch(fetchAuthorClasses(authorId, DEFAULT_DATE))
  }, [currentListing?.author?.id])

  const viewportWidth = viewport?.width

  const isMobileLayout = useMemo(() => {
    return viewportWidth ? viewportWidth < MAX_MOBILE_SCREEN_WIDTH : false
  }, [viewportWidth])

  const date = DEFAULT_DATE

  useEffect(() => {
    const authorId = currentListing?.author?.id?.uuid
    authorId && onFetchAuthorFacilities(authorId, date)
  }, [currentListing.author?.id?.uuid, date, maxDay])

  return (
    <>
      {!isEducationSite && currentAuthor && !!authorFacilities?.length && (
        <div id='facilities'>
          <h4 className={css.upcomingTitle}>Court Bookings</h4>
          <div
            className={classNames(css.facilityContainer, {
              [css.facilityTop]: !!authorFacilities?.length,
            })}
          >
            <SectionFacilities
              listing={currentListing}
              onFetchAuthorFacilities={onFetchAuthorFacilities}
              authorFacilities={authorFacilities}
              authorFacilitiesIsLoading={authorFacilitiesIsLoading}
              onFetchTimeSlots={onFetchTimeSlots}
              facilityTimeslots={facilityTimeslots}
              bookedTimeslots={bookedTimeslots}
              intl={intl}
              addBookingData={addBookingData}
              isMobileLayout={isMobileLayout}
              setMaxDay={setMaxDay}
              maxDay={maxDay}
              CoutBooking={true}
            />
          </div>
        </div>
      )}
    </>
  )
}

export default memo(CourtBookingCalander)
