import React, { useState } from 'react'
import PropTypes, { oneOf } from 'prop-types'
import { propTypes } from 'util/types'
import { Avatar, UserDisplayName, BookingInfoMaybe } from 'components/index'
import classNames from 'classnames'
import {
  InboxItem,
  InboxEnquireItem,
  PrimaryButton,
  SecondaryButton,
  Portal,
  Modal,
  RescheduleModal,
  NamedLink,
} from 'components'
import {
  txHasBeenDelivered,
  txIsAccepted,
  txIsReadyToComplete,
  txIsRequested,
  txIsRescheduled,
  txIsRescheduledByCustomer,
  txIsRescheduledByProvider,
} from 'util/transaction'
import { FormattedMessage, intlShape } from 'util/reactIntl'
import { wasTransactionCreatedBeforeTimestamp } from 'util/transaction'

import css from './InboxItemWithButtons.css'

const ConfirmationModal = props => (
  <Portal portalRoot={document.getElementById('portal-root')}>
    <Modal
      id={props.id}
      contentClassName={css.modalContent}
      isOpen={props.isOpen}
      onClose={props.onClose}
      onManageDisableScrolling={props.onManageDisableScrolling}
    >
      <div className={css.contentWrap}>
        <h3>{props.heading}</h3>
        <div className={css.confirmButtons}>
          <SecondaryButton className={css.confirmButton} onClick={props.onCancel}>
            Cancel
          </SecondaryButton>
          <PrimaryButton className={css.confirmButton} onClick={props.onConfirm}>
            Confirm
          </PrimaryButton>
        </div>
      </div>
    </Modal>
  </Portal>
)

ConfirmationModal.propTypes = {
  id: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onManageDisableScrolling: PropTypes.func.isRequired,
  heading: PropTypes.node,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
}

// Temporal feature to allow to complete transaction that had been created before
// ready-to-complete was implemented
const isOldTransaction = tx => wasTransactionCreatedBeforeTimestamp(tx, '2020-09-06 23:00:00')

function InboxItemWithButtons (props) {
  const [isCompleteModalOpen, setCompleteModalOpen] = useState(false)
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [isDeclineModalOpen, setDeclineModalOpen] = useState(false)
  const [isAcceptModalOpen, setAcceptModalOpen] = useState(false)
  const [isRescheduleModalOpen, setRescheduleModalOpen] = useState(false)

  const completeHandler = e => {
    props.onComplete(props.tx)
    setCompleteModalOpen(false)
  }

  const cancelHandler = e => {
    props.onCancel(props.tx)
    setCancelModalOpen(false)
  }

  const declineHandler = e => {
    props.onDecline(props.tx)
    setDeclineModalOpen(false)
  }

  const acceptHandler = e => {
    props.onAccept(props.tx)
    setAcceptModalOpen(false)
  }

  // const selectHandler = value => {
  //   props.onSelect(props.tx);
  // };

  const bookAgainHandler = () => {
    props.onBookAgain(props.tx)
  }

  const rescheduleHandler = () => {
    props.onReschedule(props.tx)
  }

  const openRescheduleModal = () => {
    props.onFetchMonthlyTimeSlots(props.tx.listing)
    setRescheduleModalOpen(true)
  }
  const isSallerMode = props?.currentUser?.attributes?.profile?.publicData?.sellerMode
  const isSale = props.type === 'sale'
  const canReschedule = props.tx.booking && !props.tx.listing.attributes.deleted
  const { customer, provider, listing } = props.tx
  const isOrder = props.type === 'order'
  const otherUser = isOrder ? provider : customer
  const otherUserDisplayName = (
    <UserDisplayName user={otherUser ? otherUser : null} intl={props.intl} />
  )
  const listingName = listing && listing.attributes.title

  const currentUser = props.currentUser
  const stateData = props.stateData

  const isProviderOrAdmin =
    currentUser?.id?.uuid === provider?.id?.uuid ||
    currentUser?.id?.uuid === '62d52af9-b0af-4552-8e6e-96d078ba12db'

  return (
    <div className={css.bokkingMain}>
      {(isSale || isOrder) && (
        <>
          <div className={css.bookingDetailsMain}>
            <div className={css.userBookingDetails}>
              {/* {props.onSelect && (
                <div
                  className={cns(
                    css.checkboxField,
                    props.isReadyToCompleteState === true && css.isReadyToCompleteCheckboxField
                  )}
                >
                  <input
                    onChange={selectHandler}
                    className={css.checkboxInput}
                    type="checkbox"
                    id={props.tx.id.uuid}
                    checked={props.isSelected}
                  />
                <label htmlFor={props.tx.id.uuid}></label>
                </div>
              )} */}
              <div className={css.userDetail}>
                <div className={css.itemAvatar}>
                  <Avatar className={css.avatarUser} user={otherUser} />
                </div>
                <NamedLink
                  className={css.linkClasses}
                  name={isOrder ? 'OrderDetailsPage' : 'SaleDetailsPage'}
                  params={{ id: props.tx.id.uuid }}
                >
                  <div className={css.clientBookListingWrapper}>
                    <div className={css.userDisplayNameWrapper}>{otherUserDisplayName}</div>
                    <div className={css.listingNameWrapper}>{listingName}</div>
                  </div>
                </NamedLink>
              </div>
            </div>
            <div className={css.bookingDateDetails}>
              <div className={css.stateDetail}>
                <BookingInfoMaybe
                  bookingClassName={props.stateData.bookingClassName}
                  intl={props.intl}
                  isOrder={isOrder}
                  tx={props.tx}
                  unitType={props.unitType}
                />
              </div>
              <div
                className={classNames(
                  css.stateName,
                  {
                    [css.stateDelivered]: stateData.state === 'Delivered',
                    [css.stateAccepted]: stateData.state === 'Accepted',
                    [css.stateCancelled]: stateData.state === 'Cancelled',
                  },
                  props.stateData.stateClassName
                )}
              >
                {/* {props.stateData.state === 'Delivered' && (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <circle
                      cx="5.89023"
                      cy="5.89023"
                      r="5.23576"
                      stroke="#31D296"
                      strokeWidth="1.30894"
                    />
                    <path
                      d="M3.92725 6.03641L5.23619 7.52715L8.50854 4.58203"
                      stroke="#31D296"
                      strokeWidth="1.30894"
                    />
                  </svg>
                )} */}
                {props.stateData.state}
                {txIsRescheduled(props.tx) ? (
                  <div className={classNames(css.nameEmphasized, css.stateActionNeeded)}>
                    {(currentUser &&
                      currentUser.id &&
                      provider &&
                      provider.id &&
                      txIsRescheduledByProvider(props.tx) &&
                      currentUser.id.uuid === provider.id.uuid) ||
                    (currentUser &&
                      currentUser.id &&
                      customer &&
                      customer.id &&
                      txIsRescheduledByCustomer(props.tx) &&
                      currentUser.id.uuid === customer.id.uuid) ? (
                      <FormattedMessage id={'InboxPage.rescheduledByYou'} />
                    ) : (
                      <FormattedMessage
                        className={css.otherReshedule}
                        id={'InboxPage.rescheduledByOther'}
                        values={{ otherUserDisplayName }}
                      />
                    )}
                  </div>
                ) : null}
              </div>
            </div>
          </div>
          {isSale && txIsRequested(props.tx) && (
            <div className={css.btnWrap}>
              <PrimaryButton className={css.button} onClick={() => setAcceptModalOpen(true)}>
                <FormattedMessage id={'CalendarPageBookingDay.accept'} />
              </PrimaryButton>

              <SecondaryButton
                className={css.cancelButton}
                onClick={() => setDeclineModalOpen(true)}
              >
                <FormattedMessage id={'CalendarPageBookingDay.decline'} />
              </SecondaryButton>
            </div>
          )}
        </>
      )}

      {/* {(isSale || isOrder) && props.onSelect && (
        <div
          className={cns(
            css.checkboxField,
            props.isReadyToCompleteState === true && css.isReadyToCompleteCheckboxField
          )}
        >
          <input
            onChange={selectHandler}
            className={css.checkboxInput}
            type="checkbox"
            id={props.tx.id.uuid}
            checked={props.isSelected}
          />
          <label htmlFor={props.tx.id.uuid}></label>
        </div>
      )} */}
      {props.isEnquiryTab ? (
        <InboxEnquireItem
          type={props.type}
          tx={props.tx}
          intl={props.intl}
          onEnquireMessageModalOpen={props.onEnquireMessageModalOpen}
        />
      ) : isSale || isOrder ? (
        <></>
      ) : (
        <InboxItem
          unitType={props.unitType}
          type={props.type}
          tx={props.tx}
          intl={props.intl}
          stateData={props.stateData}
          currentUser={props.currentUser}
        />
      )}
      {/* {!isSale && !isOrder && txHasBeenDelivered(props.tx) && ( */}
      {isOrder && txHasBeenDelivered(props.tx) && (
        <div className={css.btnWrap}>
          <PrimaryButton className={css.button} onClick={bookAgainHandler}>
            <FormattedMessage id={'CalendarPageBookingDay.bookAgain'} />
          </PrimaryButton>
        </div>
      )}
      {!isSale && !isOrder && txIsRequested(props.tx) && (
        <div className={css.btnWrap}>
          <PrimaryButton className={css.button} onClick={() => setAcceptModalOpen(true)}>
            <FormattedMessage id={'CalendarPageBookingDay.accept'} />
          </PrimaryButton>

          <SecondaryButton className={css.cancelButton} onClick={() => setDeclineModalOpen(true)}>
            <FormattedMessage id={'CalendarPageBookingDay.decline'} />
          </SecondaryButton>
        </div>
      )}
      {/* {!isSale && !isOrder && txIsReadyToComplete(props.tx) && ( */}
      {(isSale || isOrder) && txIsReadyToComplete(props.tx) && (
        <div className={css.btnWrap}>
          {canReschedule && isProviderOrAdmin && isSale && isSallerMode &&(
            <PrimaryButton className={css.buttonReschedule} onClick={openRescheduleModal}>
              <FormattedMessage id={'CalendarPageBookingDay.reschedule'} />
            </PrimaryButton>
          )}

          <PrimaryButton className={css.button} onClick={() => setCompleteModalOpen(true)}>
            <FormattedMessage id={'CalendarPageBookingDay.complete'} />
          </PrimaryButton>

          <SecondaryButton className={css.cancelButton} onClick={() => setCancelModalOpen(true)}>
            <FormattedMessage id={'CalendarPageBookingDay.cancel'} />
          </SecondaryButton>
        </div>
      )}
      {/* {!isSale && !isOrder && txIsAccepted(props.tx) && ( */}
      {(isSale || isOrder) && txIsAccepted(props.tx) && (
        <div className={css.btnWrap}>
          {isOldTransaction(props.tx) && (
            <PrimaryButton className={css.button} onClick={() => setCompleteModalOpen(true)}>
              <FormattedMessage id={'CalendarPageBookingDay.complete'} />
            </PrimaryButton>
          )}
          {canReschedule && isProviderOrAdmin ? (
            <PrimaryButton className={css.buttonReschedule} onClick={openRescheduleModal}>
              <FormattedMessage id={'CalendarPageBookingDay.reschedule'} />
            </PrimaryButton>
          ) : null}

          {!isOrder && (
            <SecondaryButton className={css.cancelButton} onClick={() => setCancelModalOpen(true)}>
              <FormattedMessage id={'CalendarPageBookingDay.cancel'} />
            </SecondaryButton>
          )}
        </div>
      )}

      {/* {(isSale || isOrder) && txIsAccepted(props.tx) && canReschedule && (
        <div className={css.btnWrap}>
          <PrimaryButton className={css.buttonReschedule} onClick={openRescheduleModal}>
            <FormattedMessage id={'CalendarPageBookingDay.reschedule'} />
          </PrimaryButton>
        </div>
      )} */}
      <ConfirmationModal
        id={'confirm-complete'}
        isOpen={isCompleteModalOpen}
        onClose={() => setCompleteModalOpen(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        heading={'Are you sure?'}
        onConfirm={completeHandler}
        onCancel={() => setCompleteModalOpen(false)}
      />
      <ConfirmationModal
        id={'confirm-cancel'}
        isOpen={isCancelModalOpen}
        onClose={() => setCancelModalOpen(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        heading={'Are you sure?'}
        onConfirm={cancelHandler}
        onCancel={() => setCancelModalOpen(false)}
      />
      <ConfirmationModal
        id={'confirm-decline'}
        isOpen={isDeclineModalOpen}
        onClose={() => setDeclineModalOpen(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        heading={'Are you sure?'}
        onConfirm={declineHandler}
        onCancel={() => setDeclineModalOpen(false)}
      />
      <ConfirmationModal
        id={'confirm-accept'}
        isOpen={isAcceptModalOpen}
        onClose={() => setAcceptModalOpen(false)}
        onManageDisableScrolling={props.onManageDisableScrolling}
        heading={'Are you sure?'}
        onConfirm={acceptHandler}
        onCancel={() => setAcceptModalOpen(false)}
      />
      {canReschedule && (
        <RescheduleModal
          id={'reschedule'}
          isOpen={isRescheduleModalOpen}
          onClose={() => setRescheduleModalOpen(false)}
          onManageDisableScrolling={props.onManageDisableScrolling}
          heading={'Reschedule your booking'}
          onReschedule={rescheduleHandler}
          onCancelReschedule={() => setRescheduleModalOpen(false)}
          intl={props.intl}
          tx={props.tx}
          rescheduleBooking={props.rescheduleBooking}
          rescheduleRequestInProgress={props.rescheduleRequestInProgress}
          rescheduleError={props.rescheduleError}
          rescheduledsuccessfully={props.rescheduledsuccessfully}
          isSale={isSale}
          onFetchTimeSlots={props.onFetchTimeSlots}
          onFetchMonthlyTimeSlots={props.onFetchMonthlyTimeSlots}
          monthlyTimeSlots={props.monthlyTimeSlots}
          isTimeSlotesLoading={props.isTimeSlotesLoading}
          type={props.type}
        />
      )}
    </div>
  )
}

InboxItemWithButtons.propTypes = {
  unitType: propTypes.bookingUnitType.isRequired,
  type: oneOf(['order', 'sale']).isRequired,
  tx: propTypes.transaction.isRequired,
  intl: intlShape.isRequired,
  stateData: PropTypes.object,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onDecline: PropTypes.func,
  onAccept: PropTypes.func,
  onSelect: PropTypes.func,
  onReschedule: PropTypes.func,
  isSelected: PropTypes.bool,
}

export default InboxItemWithButtons
