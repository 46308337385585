import React from 'react';
import { OutSideCloseCard, NamedLink, IconSpinner } from 'components';
import { Avatar, InlineTextButton, Menu, MenuLabel, MenuContent, MenuItem } from '../../components';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import FilterPopup from './FilterPopUp';
import routeConfiguration from '../../routeConfiguration';
import { createResourceLocatorString } from '../../util/routes';
import ProbuddyLogo from '../../assets/HomePage/ProbuddylogoWhite.svg';
import WhatsappImg from '../../assets/whatsappIcon.svg';
import { ReactComponent as HeaderSearchIcon } from '../../assets/HomePage/HeaderSearchIcon.svg';
import HeaderSearchIconImg from '../../assets/HomePage/HeaderSearchIcon.svg';
import ProbuddylogoBlue from '../../assets/ProbuddylogoBlue.svg';
import { ReactComponent as LoginIcon } from '../../assets/user.svg';
// import education from '../../assets/education.svg';
// import sportsSvg from '../../assets/sports.svg';
// import filter from '../../assets/HomePage/filter.svg';
import { HomePageLocationSearchForm } from '../../forms';
import config from '../../config';
import { FormattedMessage, injectIntl } from 'react-intl';
import {
  searchListings,
  onSearchFilter,
  onSearchSubCategory,
  onSearchListingTab,
} from './HeaderContainer.duck';
import { queryPromotedListings, showListing } from '../HomePage/HomePage.duck';
import { parse } from 'util/urlHelpers';
import HeaderTabs from './HeaderTabs';
import classNames from 'classnames';
import { MobileHeaderContainer } from '../../containers';
import moment from 'moment';
import { logout } from 'ducks/Auth.duck';
import { getMarketplaceEntities } from 'ducks/marketplaceData.duck';
import css from './HeaderContainer.css';

class HeaderContainerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      disableFilterScroll: false,
      activeSearchTab: '',
      showFilter: props.showFilter,
      min: 0,
      max: 1000,
      activeFilterTab: null,
      selectedSubCategory: props.searchActivity,
      selectedLocation: props.searchLocation,
      showActivityPopup: false,
      modalOpen: false,
      isScrolling: false,
      showHostOrMember: false,
    };
    this.handleProviderQueryChange = this.handleProviderQueryChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onSubCategorySearch = this.onSubCategorySearch.bind(this);
    this.onSelectSearchTab = this.onSelectSearchTab.bind(this);
    this.resetPage = this.resetPage.bind(this);
    this.formContainer = React.createRef();
    this.onChangePrice = this.onChangePrice.bind(this);
    this.getQueryParameters = this.getQueryParameters.bind(this);
    this.onKeywordSeleceted = this.onKeywordSeleceted.bind(this);
  }

  componentDidMount() {
    const queryString = parse(this.props.location.search);
    if (queryString && queryString?.listing_type) {
      this.setState({ activeFilterTab: queryString.listing_type });
    }
  }

  onSelectSearchTab(searchTab) {
    this.setState({
      activeSearchTab: searchTab,
      showActivityPopup: true,
    });
    this.props.handleHeaderSearchBar && this.props.handleHeaderSearchBar(true);
    this.props.handleOpacityChange && this.props.handleOpacityChange(true);
  }

  handleProviderQueryChange(providerQueryValue) {
    providerQueryValue.length &&
      this.props.searchListings({
        keywords: providerQueryValue,
        pub_listing_type: this.props.isEducationSite
          ? 'academy,courses,tutor,sessions,remote tutor'
          : this.props.isTennisSite
          ? 'player-profile,product,event'
          : 'Company,company,listing,product,membership,freelancer,class,facility,event',
      });
  }

  getQueryParameters = () => {
    const { currentSearchParams } = this.props;
    const { search, selectedPlace } = this.props.searchLocation || {
      predictions: [],
      search: '',
      selectedPlace: {
        address: '',
      },
    };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...currentSearchParams,
      ...originMaybe,
      pub_listing_type: this.state.activeFilterTab,
      pub_sub_category:
        (this.state.selectedSubCategory && this.state.selectedSubCategory.key) ||
        (this.props.searchActivity && this.props.searchActivity.key) ||
        null,
      address: !!search ? search : null,
      bounds: bounds ? bounds : null,
    };
    return searchParams;
  };

  handleSubmit = values => {
    const { searchActivity, searchLocation, selectedListing } = values;
    this.setState({
      activeSearchTab: '',
      selectedSubCategory: searchActivity.id ? null : searchActivity,
    });
    const date = moment().format('YYYY-MM-DD');
    this.props.onSearchFilter(values);
    this.props.onChangeActivity && this.props.onChangeActivity(searchActivity);
    this.props.handleOpacityChange(false);
    const params = this.getQueryParameters();
    const { search, selectedPlace } = values.searchLocation
      ? searchLocation
      : {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        };
    const { origin, bounds } = selectedPlace ? selectedPlace : {};
    const originMaybe = config.sortSearchByDistance ? { origin } : {};
    const searchParams = {
      ...params,
      ...originMaybe,
      pub_sub_category: searchActivity && searchActivity.key ? searchActivity.key : null,
      pub_listing_type:
        searchActivity && searchActivity.title
          ? null
          : selectedListing
          ? selectedListing
          : this.state.activeFilterTab,
      keywords: searchActivity?.id
        ? searchActivity.title
        : searchActivity?.key
        ? null
        : searchActivity?.label
        ? searchActivity.label
        : searchActivity,
      address: search ? search : null,
      bounds: bounds ? bounds : null,
      dates: this.state.activeFilterTab === 'class' ? `${date},${date}` : null,
      times: this.state.activeFilterTab === 'class' ? '00:00,23:59' : null,
    };
    !(searchActivity && searchActivity.id && searchActivity.title)
      ? this.props.onSearch(searchParams)
      : this.props.onkeywordSearch && this.props.onkeywordSearch(searchActivity);
    // this.props.onFetchAuthorListing([searchActivity?.id]);
    this.props.handleHeaderSearchBar(false);
  };

  onKeywordSeleceted = activity => {
    this.setState({
      activeSearchTab: '',
      selectedSubCategory: activity.id ? null : activity,
    });
    this.props.onSearchSubCategory(activity);
    this.props.onChangeActivity && this.props.onChangeActivity(activity);
    this.props.handleOpacityChange(false);
    this.props.handleHeaderSearchBar(false);
    this.props.onkeywordSearch && this.props.onkeywordSearch(activity);
  };

  onSubCategorySearch = item => {
    const combineCategory = ['Adventure', 'Racket Sports', 'Wellness', 'Fitness Classes'];
    this.setState({ activeSearchTab: '', selectedSubCategory: item });
    !combineCategory.includes(item.label) && this.props.onSearchSubCategory(item);
    // : this.props.onSearchSubCategory();
    const params = this.getQueryParameters();
    const searchParams = {
      ...params,
      pub_sub_category: item.key,
    };
    this.props.onSearch(searchParams);
  };

  onTabChangeSearch = tab => {
    this.props.currentTab && this.props.currentTab(tab);
    this.setState({
      activeFilterTab: tab.id,
      showActivityPopup: true,
    });
    this.props.handleOpacityChange(false);
    const params = this.getQueryParameters();
    const date = moment().format('YYYY-MM-DD');
    // .add(i, 'days');
    const searchParams = {
      ...params,
      pub_kids_promotions: null,
      pub_listing_type: tab.id,
      dates: tab.id === 'class' || tab.id === 'courses' ? `${date},${date}` : null,
      times: tab.id === 'class' || tab.id === 'courses' ? '00:00,23:59' : null,
      //times: tab.id === 'class' ? `${moment().hours()}:${moment().minutes()},23:59` : null,
    };
    this.props.onSearchListingTab(tab.id);
    this.props.onTabChangeSearch && this.props.onTabChangeSearch(searchParams);
    // this.props.onSearch(searchParams);
  };

  onChangePrice = value => {
    this.props.handleOpacityChange(false);
    this.setState({ showFilter: false });
    this.props.disableFilterScroll && this.props.disableFilterScroll(false);
    const params = this.getQueryParameters();
    const searchParams = {
      ...params,
      price: `${this.state.min},${this.state.max}`,
    };
    this.props.onSearch(searchParams);
  };

  resetPage() {
    if (
      this.props.history.location.pathname === '/profile-avatar' &&
      this.props.userHasImage === null
    ) {
      this.props.history.push(
        createResourceLocatorString('ProfileAvatarPage', routeConfiguration(), {})
      );
      this.props.imageUploadRedirectError && this.props.imageUploadRedirectError(true);
    } else {
      this.props.history.push(
        createResourceLocatorString(
          this.props.isEducationSite
            ? 'EducationPage'
            : this.props.isTennisSite
            ? 'TennisPage'
            : 'HomePage',
          routeConfiguration(),
          {}
        )
      );
      window?.location && window.location.reload(false);
    }
  }

  render() {
    const { activeSearchTab } = this.state;
    const {
      searchActivity,
      searchLocation,
      searchSelectedListing,
      isMobileLayout,
      viewport,
      hideBackgroundImage,
      currentUser,
      isEducationSite,
      isTennisSite,
    } = this.props;

    // let isPrivateSite = false;
    // if (typeof window !== 'undefined' && !!window.sessionStorage) {
    //   isPrivateSite = window.sessionStorage.getItem('privateSiteUrl') ? true : false;
    // }

    const onLogoutClick = () => {
      if (this.props.isAuthenticated) {
        // setIsSellerMode(false);
        localStorage.removeItem('isProfile');
        this.props.onLogout();
      } else {
        this.props.history.push(createResourceLocatorString('LoginPage', routeConfiguration(), {}));
      }
    };

    // const onLoginVoucherUser = () => {
    //   if (this.props.isAuthenticated) {
    //     localStorage.removeItem('isProfile');
    //     this.props.logout();
    //   } else {
    //     this.props.history.push(
    //       createResourceLocatorString('NewSignupPage', routeConfiguration(), {})
    //     );
    //   }
    // };

    const profileMenu = (
      <Menu isHoverDropDown={true} className={css.profileMenuRoot}>
        <MenuLabel
          className={classNames(css.profileMenuLabel)}
          isOpenClassName={css.profileMenuIsOpen}
        >
          {/* <span>Hi, {currentUser && currentUser?.attributes?.profile?.firstName} &nbsp;</span> */}
          <div className={css.activeUser}>
            {this.props.isAuthenticated ? (
              <>
                <Avatar className={css.avatar} user={currentUser} disableProfileLink />
                <div className={css.active}></div>
              </>
            ) : (
              <div className={css.loginIcon}>
                <LoginIcon />
              </div>
            )}
          </div>
        </MenuLabel>
        <MenuContent className={css.profileMenuContent}>
          {/* <MenuItem key="ProfileSettingsPage">
            <NamedLink className={classNames(css.profileSettingsLink)} name="ProfileSettingsPage">
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.profileSettingsLink" />
            </NamedLink>
          </MenuItem> */}
          <MenuItem key="BookingPage">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name="InboxPage"
              params={{ tab: 'orders', state: 'upcoming' }}
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.Bookings" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="AccountSettingsPage">
            <NamedLink
              className={classNames(css.yourListingsLink)}
              name={
                isEducationSite
                  ? 'EducationAccountSettingsPage'
                  : isTennisSite
                  ? 'TennisAccountSettingsPage'
                  : 'AccountSettingsPage'
              }
            >
              <span className={css.menuItemBorder} />
              <FormattedMessage id="TopbarDesktop.accountSettingsLink" />
            </NamedLink>
          </MenuItem>
          <MenuItem key="logout">
            <InlineTextButton rootClassName={css.logoutButton} onClick={onLogoutClick}>
              <span className={css.menuItemBorder} />
              {!this.props.logoutInProgress ? (
                this.props.isAuthenticated ? (
                  <FormattedMessage id="TopbarDesktop.logout" />
                ) : (
                  <FormattedMessage id="TopbarDesktop.login" />
                )
              ) : (
                !!this.props.logoutInProgress && <IconSpinner />
              )}
            </InlineTextButton>
          </MenuItem>
        </MenuContent>
      </Menu>
    );

    return (
      <>
        <OutSideCloseCard
          show={true}
          onClickOutside={() => {
            this.setState({ activeSearchTab: '' });
            this.props.handleHeaderSearchBar && this.props.handleHeaderSearchBar(false);
            this.props.handleOpacityChange && this.props.handleOpacityChange(false);
          }}
        >
          <div
            className={classNames(
              css.root,
              { [css.educationalRoot]: isEducationSite }
              // { [css.tennisRoot]: isTennisSite }
            )}
          >
            <div className={css.rootChild}>
              {/* {showTopCategoryPopup ? <div className={css.overlayHeader} /> : null} */}

              <div
                className={classNames(css.topHeader, {
                  [css.topHeaderActive]: activeSearchTab !== '',
                  [css.hidelogoTopHeader]: this.props.isScrolling || this.props.hideLogo,
                })}
              >
                {!isMobileLayout && (
                  <>
                    <div
                      className={classNames({
                        [css.probuddyLogo]: activeSearchTab === '',
                        [css.activeProbuddyLogo]: activeSearchTab !== '',
                      })}
                    >
                      <div onClick={() => this.resetPage()}>
                        <img
                          src={isEducationSite ? ProbuddylogoBlue : ProbuddyLogo}
                          alt="ProbuddyLogo"
                        />
                      </div>
                    </div>
                  </>
                )}

                {isMobileLayout ? (
                  <MobileHeaderContainer
                    onSearch={this.props.onSearch}
                    onTabChangeSearch={this.props.onTabChangeSearch}
                    handleOpacityChange={this.props.handleOpacityChange}
                    handleHeaderSearchBar={this.props.handleHeaderSearchBar}
                    onChangeActivity={value => this.setState({ selectedSubCategory: value })}
                    isScrolling={this.props.isScrolling}
                    searchModalOpen={value => {
                      this.setState({ modalOpen: value });
                      this.props.searchModalOpen && this.props.searchModalOpen(value);
                    }}
                    disableFilterScroll={value => this.setState({ disableFilterScroll: value })}
                    currentTab={tab => {
                      this.setState({ currentTab: tab });
                    }}
                    rootClassName={css.rootClassName}
                    hideOverlay={true}
                    hideLogo={this.props.hideLogo}
                  />
                ) : (
                  <>
                    <div
                      className={classNames(css.middleSection, {
                        [css.middleSectionActive]: activeSearchTab !== '',
                      })}
                    >
                      <div
                        className={classNames({
                          [css.bottomContainerActive]: activeSearchTab !== '',
                          [css.bottomContainer]: activeSearchTab === '',
                        })}
                        ref={this.formContainer}
                      >
                        <div className={css.headerTabsContainer}>
                          <HeaderTabs
                            initialTab={this.state.activeFilterTab}
                            onChangeTab={tab => {
                              this.onTabChangeSearch(tab);
                            }}
                            isEducationSite={isEducationSite}
                            // isTennisSite={isTennisSite}
                          />
                        </div>
                        <div
                          className={classNames(css.topHeaderSearchContainer, {
                            [css.outSideCloseCardActive]: activeSearchTab !== '',
                            [css.subSectionActive]: activeSearchTab === '',
                            [css.topHeaderSearchContainerActive]: activeSearchTab !== '',
                            [css.topHeaderSearchContainerEdu]: isEducationSite,
                            // [css.topHeaderSearchContainerTen]: isTennisSite,
                          })}
                        >
                          <HomePageLocationSearchForm
                            className={classNames(css.searchLink)}
                            changeOpacity={value => {
                              this.props.handleOpacityChange &&
                                this.props.handleOpacityChange(value);
                            }}
                            currentOfSet={
                              this.formContainer.current && this.formContainer.current.offsetLeft
                            }
                            getSuggestedListing={this.props.searchListings}
                            isMobileLayout={isMobileLayout}
                            showActivityPopup={this.state.showActivityPopup}
                            desktopInputRoot={classNames(css.headerSearchWithLeftPadding)}
                            onSubmit={this.handleSubmit}
                            onKeywordSeleceted={this.onKeywordSeleceted}
                            initialValues={{
                              searchActivity: searchActivity,
                              searchLocation: searchLocation ? searchLocation : null,
                              selectedListing: searchSelectedListing,
                            }}
                            intl={this.props.intl}
                            history={this.props.history}
                            suggestedListings={this.props.suggetListings}
                            handleProviderQueryChange={this.handleProviderQueryChange}
                            viewport={viewport}
                            isEducationSite={isEducationSite}
                            // isTennisSite={isTennisSite}
                            // headerConatinerRef={this.formContainer}
                          />
                        </div>
                      </div>
                      <div
                        className={classNames({
                          [css.topFilterContainerActive]: activeSearchTab !== '',
                          [css.topFilterContainer]: activeSearchTab === '',
                        })}
                      >
                        <div
                          className={classNames(css.topHeaderSearchContainer, {
                            [css.topHeaderSearchContainerActive]: activeSearchTab !== '',
                            [css.topHeaderSearchContainerEdu]: isEducationSite,
                            // [css.topHeaderSearchContainerTen]: isTennisSite,
                          })}
                        >
                          {/* <div
                            className={css.searchAnyActivityLink}
                            onClick={() => {
                              this.onSelectSearchTab('anyActivity');
                              this.setState({
                                showActivityPopup: true,
                              });
                            }}
                          >
                            <FormattedMessage id="HomePageHeaderSection.AnyActivity" />
                          </div>
                          <span className={css.divider} /> */}
                          <div
                            className={css.searchAnyRatingLink}
                            onClick={() => {
                              // this.onSelectSearchTab('anyWhere');
                              // this.setState({
                              //   showActivityPopup: true,
                              // });
                              this.props.history.push(
                                `${
                                  isEducationSite
                                    ? '/education/jobs-board'
                                    : isTennisSite
                                    ? '/tennis/jobs-board'
                                    : '/jobs-board'
                                }`
                              )
                            }}
                          >
                            <FormattedMessage id="HomePageHeaderSection.AnyWhere" />
                            {isEducationSite && isTennisSite ? (
                              <HeaderSearchIcon />
                            ) : (
                              <img src={HeaderSearchIconImg} alt={' '} />
                            )}
                          </div>
                          {/* <span className={css.divider} />

                        <div
                          className={css.searchAnyRatingLink}
                          onClick={() =>
                            this.setState({ activeSearchTab: 'anyRating', showActivityPopup: true })
                          }
                        >
                          <FormattedMessage id="HomePageHeaderSection.AnyListing" />
                        </div> */}
                          <FilterPopup
                            showFilter={!isMobileLayout && this.state.showFilter}
                            onClickOutside={() => {
                              this.props.handleOpacityChange(false);
                              this.setState({ showFilter: false });
                              this.props.disableFilterScroll &&
                                this.props.disableFilterScroll(false);
                            }}
                            onValueChange={value => {
                              this.setState({ min: value[0], max: value[1] });
                            }}
                            onChangeInputPrice={(value, type) => {
                              type === 'min'
                                ? this.setState({ min: value })
                                : this.setState({ max: value });
                            }}
                            intl={this.props.intl}
                            max={this.state.max}
                            min={this.state.min}
                            applyPriceFilter={this.onChangePrice}
                          />
                        </div>
                        {/* <div
                          className={classNames(css.topHeaderSearchContainer, {
                            [css.topHeaderSearchContainerActive]: activeSearchTab !== '',
                            [css.topHeaderSearchContainerEdu]: isEducationSite,
                            // [css.topHeaderSearchContainerTen]: isTennisSite,
                          })}
                        > */}
                          {/* <div
                            className={css.searchAnyActivityLink}
                            onClick={() => {
                              this.onSelectSearchTab('anyActivity');
                              this.setState({
                                showActivityPopup: true,
                              });
                            }}
                          >
                            <FormattedMessage id="HomePageHeaderSection.AnyActivity" />
                          </div>
                          <span className={css.divider} /> */}
                          <div
                            className={css.whatsappRatingLink}
                            onClick={() => {
                              window.location.href = 'https://chat.whatsapp.com/LufxH8OOVM7BUBA0jbkrwH';
                            }}
                          >
                            <img src={WhatsappImg} alt="whatsapp-link" className={css.whatsappIcon}/>
                            <FormattedMessage id="HomePageHeaderSection.whatsappCommunityGroup" />
                          </div>
                          {/* <span className={css.divider} />

                        <div
                          className={css.searchAnyRatingLink}
                          onClick={() =>
                            this.setState({ activeSearchTab: 'anyRating', showActivityPopup: true })
                          }
                        >
                          <FormattedMessage id="HomePageHeaderSection.AnyListing" />
                        </div> */}
                          <FilterPopup
                            showFilter={!isMobileLayout && this.state.showFilter}
                            onClickOutside={() => {
                              this.props.handleOpacityChange(false);
                              this.setState({ showFilter: false });
                              this.props.disableFilterScroll &&
                                this.props.disableFilterScroll(false);
                            }}
                            onValueChange={value => {
                              this.setState({ min: value[0], max: value[1] });
                            }}
                            onChangeInputPrice={(value, type) => {
                              type === 'min'
                                ? this.setState({ min: value })
                                : this.setState({ max: value });
                            }}
                            intl={this.props.intl}
                            max={this.state.max}
                            min={this.state.min}
                            applyPriceFilter={this.onChangePrice}
                          />
                        {/* </div> */}
                      </div>
                    </div>{' '}
                    <div className={css.imgWrap}>
                      {activeSearchTab === '' && !isMobileLayout ? (
                        <>
                          {/* {!isEducationSite ? (
                            <NamedLink name="EducationPage" className={css.switchToEdu}>
                              Switch to Education
                              <img src={education} alt="logo" />
                            </NamedLink>
                          ) : (
                            <NamedLink name="HomePage" className={css.switchToSport}>
                              Switch to Sports
                              <img src={sportsSvg} alt="logo" />
                            </NamedLink>
                          )} */}
                          <div className={css.playerMemberButton}>
                            {/* {!isEducationSite && (
                              <button
                                onClick={value =>
                                  this.setState({
                                    ...value,
                                    showHostOrMember: !this.state.showHostOrMember,
                                  })
                                }
                                className={classNames(css.sellerButton, {
                                  [css.sellerButtonEdu]: isEducationSite,
                                  [css.sellerButtonTen]: isTennisSite,
                                })}
                              >
                                <NamedLink className={css.btnLogin} name={'NewPlayerProfilePage'}>
                                  Player Profile
                                </NamedLink>
                              </button>
                            )} */}
                            <button
                              onClick={value =>
                                this.setState({
                                  ...value,
                                  showHostOrMember: !this.state.showHostOrMember,
                                })
                              }
                              className={classNames(css.sellerButton, {
                                [css.sellerButtonEdu]: isEducationSite,
                                [css.sellerButtonTen]: isTennisSite,
                              })}
                            >
                              <NamedLink
                                className={css.btnLogin}
                                name={isEducationSite ? 'BecomeTutorPage' : 'BecomeVendorPage'}
                              >
                                {isEducationSite ? 'Become a Tutor' : ' Become a Coach'}
                              </NamedLink>

                              {/* {this.props.selectedListing === 'player-profile'
                              ? 'Memberships & Profiles'
                              : ' Memberships'} */}
                              <OutSideCloseCard
                                show={this.state.showHostOrMember}
                                onClickOutside={value =>
                                  this.setState({
                                    ...value,
                                    showHostOrMember: !this.state.showHostOrMember,
                                  })
                                }
                                className={css.showActivityContainer}
                              >
                                <div className={css.becomeHostMember}>
                                  {/* {!isEducationSite && (
                                  <NamedLink
                                    className={css.becomeHostMemberWrap}
                                    name="BecomeMemberPage"
                                  >
                                    <img
                                      src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeMember_oaizaz.png"
                                      className={css.becomeHostMemberImg}
                                      alt="Become a Member"
                                    />
                                    <span className={css.becomeHostMemberText}>
                                      Sports Packages
                                    </span>
                                  </NamedLink>
                                )} */}
                                  {/* <NamedLink
                                  className={css.becomeHostMemberWrap}
                                  name={isEducationSite ? 'BecomeTutorPage' : 'BecomeVendorPage'}
                                >
                                  <img
                                    src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeHost_alwafl.png"
                                    className={css.becomeHostMemberImg}
                                    alt="Become a Host"
                                  />
                                  <span className={css.becomeHostMemberText}>
                                    {isEducationSite ? 'Become a Tutor' : 'Become a Host'}
                                  </span>
                                </NamedLink> */}
                                  {/* {this.props.selectedListing === 'player-profile' && (
                                  <NamedLink
                                    className={css.becomeHostMemberWrap}
                                    name={'NewPlayerProfilePage'}
                                  >
                                    <img
                                      src="https://res.cloudinary.com/movementbuddy-pte-ltd/image/upload/v1664864117/probuddy/static/images/BecomeMemberPage/becomeMember_oaizaz.png"
                                      className={css.becomeHostMemberImg}
                                      alt=" "
                                    />
                                    <span className={css.becomeHostMemberText}>
                                      {'Add Player Profile'}
                                    </span>
                                  </NamedLink>
                                )} */}
                                </div>
                              </OutSideCloseCard>
                            </button>
                          </div>
                          {profileMenu}
                        </>
                      ) : null}
                      <div
                        // className={classNames(css.bgImgIcon, { [css.hideImg]: this.props.isScrolling })}
                        className={classNames(
                          css.bgImgIcon,
                          isMobileLayout && hideBackgroundImage
                            ? css.hideImg
                            : { [css.hideImg]: this.props.isScrolling }
                        )}
                      />
                    </div>
                  </>
                )}
              </div>
            </div>
            {!this.props.hideBottomHeader && (
              <>
                <div
                  className={classNames(css.headerDivider, {
                    [css.headerDividerEdu]: isEducationSite,
                    // [css.headerDividerTen]: isTennisSite,
                  })}
                />
              </>
            )}
          </div>
        </OutSideCloseCard>
      </>
    );
  }
}

const mapStateToProps = state => {
  const {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
  } = state.HeaderSection;
  const { logoutInProgress, isAuthenticated } = state.Auth;
  const suggetListingRef = getMarketplaceEntities(state, suggestedListings);
  const suggetListings = suggetListingRef.map(l => ({
    id: l.id,
    title: l.attributes.title,
    image: l.images,
    address: l.attributes?.publicData?.location?.address,
  }));
  return {
    suggestedListings,
    searchActivity,
    searchLocation,
    searchSelectedListing,
    suggetListings,
    logoutInProgress,
    isAuthenticated,
  };
};

const mapDispatchToProps = dispatch => ({
  searchListings: searchParams => dispatch(searchListings(searchParams)),
  onSearchFilter: searchFilters => dispatch(onSearchFilter(searchFilters)),
  onSearchSubCategory: searchSubCategory => dispatch(onSearchSubCategory(searchSubCategory)),
  queryPromotedListings: searchquery => dispatch(queryPromotedListings(searchquery)),
  showListing: listingId => dispatch(showListing(listingId)),
  onSearchListingTab: searchListing => dispatch(onSearchListingTab(searchListing)),
  onLogout: historyPush => dispatch(logout(historyPush)),
  // onSuggetListing: listing => dispatch(onSuggetListing(listing)),
  // onFetchAuthorListing: (listingRef, isOwn) => dispatch(fetchAuthorListing(listingRef, isOwn)),
});

const HeaderContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(HeaderContainerComponent);

export default HeaderContainer;
