import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { entityRefs, denormalisedResponseEntities, ensureCURLListings } from '../../util/data';
import { storableError } from '../../util/errors';
import { util as sdkUtil } from '../../util/sdkLoader';
import { formatDateStringToTz, getDefaultTimeZoneOnBrowser } from '../../util/dates';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';

import { setInitialState as clearFilter } from '../HeaderContainer/HeaderContainer.duck';
import axios from 'axios';
import omit from 'lodash/omit';
import { LISTING_TYPES } from 'util/constants';
import config from '../../config';
import qs from 'qs';
import moment from 'moment';
import { TRANSITION_ENQUIRE } from '../../util/transaction';
import { fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
const flexIntegrationSdk = require('sharetribe-flex-integration-sdk');
// ================ Action types ================ //

export const SET_INITIAL_STATE = 'app/HomePage/SET_INITIAL_STATE';

export const QUERY_PROMOTED_LISTINGS_REQUEST = 'app/HomePage/QUERY_PROMOTED_LISTINGS_REQUEST';
export const QUERY_PROMOTED_LISTINGS_SUCCESS = 'app/HomePage/QUERY_PROMOTED_LISTINGS_SUCCESS';
export const QUERY_PROMOTED_LISTINGS_ERROR = 'app/HomePage/QUERY_PROMOTED_LISTINGS_ERROR';

export const COMPANY_FREELANCER_REQUEST = 'app/HomePage/COMPANY_FREELANCER_REQUEST';
export const COMPANY_FREELANCER_SUCCESS = 'app/HomePage/COMPANY_FREELANCER_SUCCESS';
export const COMPANY_FREELANCER_ERROR = 'app/HomePage/COMPANY_FREELANCER_ERROR';

export const SEARCH_LISTINGS_REQUEST = 'app/HomePage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/HomePage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/HomePage/SEARCH_LISTINGS_ERROR';

export const QUERY_PROMOTED_LISTING_REVIEWS_REQUEST =
  'app/HomePage/QUERY_PROMOTED_LISTING_REVIEWS_REQUEST';
export const QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS =
  'app/HomePage/QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS';
export const QUERY_PROMOTED_LISTING_REVIEWS_ERROR =
  'app/HomePage/QUERY_PROMOTED_LISTING_REVIEWS_ERROR';

export const ADD_PAGINATION_DATA = 'app/HomePage/ADD_PAGINATION_DATA';

export const FETCH_COMPANIES_REQUEST = 'app/HomePage/FETCH_COMPANIES_REQUEST';
export const FETCH_COMPANIES_SUCCESS = 'app/HomePage/FETCH_COMPANIES_SUCCESS';
export const FETCH_COMPANIES_ERROR = 'app/HomePage/FETCH_COMPANIES_ERROR';

export const FETCH_ALL_LISTING_REVIEWS = 'app/HomePage/FETCH_ALL_LISTING_REVIEWS';

//author classes
export const FETCH_AUTHOR_CLASSES_REQUEST = 'app/HomePage/FETCH_AUTHOR_CLASSES_REQUEST';
export const FETCH_AUTHOR_CLASSES_SUCCESS = 'app/HomePage/FETCH_AUTHOR_CLASSES_SUCCESS';
export const FETCH_AUTHOR_CLASSES_ERROR = 'app/HomePage/FETCH_AUTHOR_CLASSES_ERROR';

//Author other listing
export const SET_AUTHORS_LISTINGS_REFS = 'app/HomePage/SET_AUTHORS_LISTINGS_REFS';
export const SHOW_LISTING_REQUEST = 'app/HomePage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/HomePage/SHOW_LISTING_ERROR';

//Enquiry
export const SEND_ENQUIRY_REQUEST = 'app/HomePage/SEND_ENQUIRY_REQUEST';
export const SEND_ENQUIRY_SUCCESS = 'app/HomePage/SEND_ENQUIRY_SUCCESS';
export const SEND_ENQUIRY_ERROR = 'app/HomePage/SEND_ENQUIRY_ERROR';

export const MATCH_ACTIVITY_LISTING_REQUEST = 'app/HomePage/MATCH_ACTIVITY_LISTING_REQUEST';

export const CREATE_MATCH_ACTIVITY_LISTING_SUCCESS =
  'app/HomePage/CREATE_MATCH_ACTIVITY_LISTING_SUCCESS';

export const CREATE_MATCH_ACTIVITY_LISTING_ERROR =
  'app/HomePage/CREATE_MATCH_ACTIVITY_LISTING_ERROR';

export const GET_RECOMMENDATION_DATA_REQUEST = 'app/HomePage/GET_RECOMMENDATION_DATA_REQUEST';
export const GET_RECOMMENDATION_DATA_SUCCESS = 'app/HomePage/GET_RECOMMENDATION_DATA_SUCCESS';
export const GET_RECOMMENDATION_DATA_ERROR = 'app/HomePage/GET_RECOMMENDATION_DATA_ERROR';

export const UPDATE_SEARCHED_LISTINGS = 'app/HomePage/UPDATE_SEARCHED_LISTINGS';
// ================ Reducer ================ //

const initialState = {
  queryPromotedListingsError: null,
  promotedListingRefs: [],
  promotedListingReviews: [],
  suggestedListings: [],
  creatingListings: false,
  companiesRefs: [],
  matchActivityListingsError: null,
  companiesError: null,
  companiesLoading: false,
  listingAllReviews: {},
  authorsListingsRefs: [],
  showListingError: null,
  gettingListings: false,
  companyFreelancerListings: [],

  //enquiry
  sendEnquiryError: null,
  sendEnquiryInProgress: false,
  pagination: {},
  recommendationList: [],
  recommendationListError: null,
  recommendationPagination: null,
};

export default function homePageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case COMPANY_FREELANCER_REQUEST:
      return {
        ...state,
        companyFreelancerListings: state.companyFreelancerListings,
      };
    case COMPANY_FREELANCER_SUCCESS:
      return { ...state, companyFreelancerListings: payload.listingRefs };
    case COMPANY_FREELANCER_ERROR:
      return {
        ...state,
        companyFreelancerListings: [],
      };
    case QUERY_PROMOTED_LISTINGS_REQUEST:
      return {
        ...state,
        promotedListingRefs: state.promotedListingRefs,
        queryPromotedListingsError: null,
        gettingListings: true,
      };
    case QUERY_PROMOTED_LISTINGS_SUCCESS:
      return { ...state, promotedListingRefs: payload.listingRefs, gettingListings: false };
    case QUERY_PROMOTED_LISTINGS_ERROR:
      return {
        ...state,
        promotedListingRefs: [],
        queryPromotedListingsError: payload,
        gettingListings: false,
      };
    case UPDATE_SEARCHED_LISTINGS:
      return {
        ...state,
        promotedListingRefs: payload,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload };
    case QUERY_PROMOTED_LISTING_REVIEWS_REQUEST:
      return { ...state, queryPromotedListingReviewsError: null };
    case QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS:
      return { ...state, promotedListingReviews: { ...state.promotedListingReviews, payload } };

    case ADD_PAGINATION_DATA:
      return { ...state, pagination: payload };

    case QUERY_PROMOTED_LISTING_REVIEWS_ERROR:
      return { ...state, reviews: [], queryPromotedListingReviewsError: payload };

    //Companies
    case FETCH_COMPANIES_REQUEST:
      return { ...state, companiesError: null, companiesLoading: true };
    case FETCH_COMPANIES_SUCCESS:
      return { ...state, companiesRefs: payload, companiesLoading: false };
    case FETCH_COMPANIES_ERROR:
      return { ...state, companiesError: payload, companiesLoading: false };

    // reviews
    case FETCH_ALL_LISTING_REVIEWS:
      return { ...state, listingAllReviews: payload };
    default:
      return state;

    //author classes
    case FETCH_AUTHOR_CLASSES_REQUEST:
      return { ...state, authorClassesIsLoading: true };
    case FETCH_AUTHOR_CLASSES_SUCCESS:
      return { ...state, authorClassesIsLoading: false, authorClassesRefs: payload };
    case FETCH_AUTHOR_CLASSES_ERROR:
      return { ...state, authorClassesIsLoading: false, authorClassesError: payload };

    //Author other listing
    case SET_AUTHORS_LISTINGS_REFS: {
      return { ...state, authorsListingsRefs: payload };
    }
    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    //enquiry
    case SEND_ENQUIRY_REQUEST:
      return { ...state, sendEnquiryInProgress: true, sendEnquiryError: null };
    case SEND_ENQUIRY_SUCCESS:
      return { ...state, sendEnquiryInProgress: false };
    case SEND_ENQUIRY_ERROR:
      return { ...state, sendEnquiryInProgress: false, sendEnquiryError: payload };

    case CREATE_MATCH_ACTIVITY_LISTING_SUCCESS:
      return {
        ...state,
        creatingListings: false,
      };

    case CREATE_MATCH_ACTIVITY_LISTING_ERROR:
      return {
        ...state,
        creatingListings: false,
        queryMatchActivityListingsError: payload,
      };

    case MATCH_ACTIVITY_LISTING_REQUEST:
      return {
        ...state,
        creatingListings: true,
      };

    case GET_RECOMMENDATION_DATA_REQUEST:
      return {
        ...state,
        recommendationList: state.recommendationList,
        recommendationPagination: state.recommendationPagination,
        recommendationListError: null,
        isLoading: true,
      };

    case GET_RECOMMENDATION_DATA_SUCCESS:
      return {
        ...state,
        //{ listingRes, pages }
        recommendationList: payload.listingRes,
        recommendationPagination: payload.pages,
        recommendationListError: null,
        isLoading: false,
      };
    case GET_RECOMMENDATION_DATA_ERROR:
      return {
        ...state,
        recommendationList: [],
        recommendationListError: payload,
        recommendationPagination: null,
        isLoading: false,
      };
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const companyFreelancerRequest = () => ({
  type: COMPANY_FREELANCER_REQUEST,
});

export const companyFreelancerSuccess = listingRefs => ({
  type: COMPANY_FREELANCER_SUCCESS,
  payload: { listingRefs },
});

export const companyFreelancerError = e => ({
  type: COMPANY_FREELANCER_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingsRequest = () => ({
  type: QUERY_PROMOTED_LISTINGS_REQUEST,
});

export const queryPromotedListingsSuccess = listingRefs => ({
  type: QUERY_PROMOTED_LISTINGS_SUCCESS,
  payload: { listingRefs },
});

export const addPaginationData = data => ({
  type: ADD_PAGINATION_DATA,
  payload: data,
});

export const queryPromotedListingsError = e => ({
  type: QUERY_PROMOTED_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const queryPromotedListingReviewsRequest = () => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_REQUEST,
});

export const allListingReviews = reviewData => ({
  type: FETCH_ALL_LISTING_REVIEWS,
  payload: reviewData,
});

export const queryPromotedListingReviewsSuccess = reviews => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_SUCCESS,
  payload: reviews,
});

export const queryPromotedListingReviewsError = e => ({
  type: QUERY_PROMOTED_LISTING_REVIEWS_ERROR,
  error: true,
  payload: e,
});

export const fetchCompaniesRequest = () => ({
  type: FETCH_COMPANIES_REQUEST,
});
export const fetchCompaniesSuccess = payload => ({
  type: FETCH_COMPANIES_SUCCESS,
  payload,
});
export const fetchCompaniesError = payload => ({
  type: FETCH_COMPANIES_ERROR,
  payload,
});

export const fetchAuthorClassesRequest = () => ({ type: FETCH_AUTHOR_CLASSES_REQUEST });
export const fetchAuthorClassesSuccess = reviews => ({
  type: FETCH_AUTHOR_CLASSES_SUCCESS,
  payload: reviews,
});
export const fetchAuthorClassesError = error => ({
  type: FETCH_AUTHOR_CLASSES_ERROR,
  error: true,
  payload: error,
});

//Author other listing
export const setAuthorsListingsRefs = author => ({
  type: SET_AUTHORS_LISTINGS_REFS,
  payload: author,
});

//enquiry
export const sendEnquiryRequest = () => ({ type: SEND_ENQUIRY_REQUEST });
export const sendEnquirySuccess = () => ({ type: SEND_ENQUIRY_SUCCESS });
export const sendEnquiryError = e => ({ type: SEND_ENQUIRY_ERROR, error: true, payload: e });

export const matchActivityListingsRequest = () => ({
  type: MATCH_ACTIVITY_LISTING_REQUEST,
});

export const matchActivityListingsSuccess = () => ({
  type: CREATE_MATCH_ACTIVITY_LISTING_SUCCESS,
});

export const matchActivityListingsError = error => ({
  type: CREATE_MATCH_ACTIVITY_LISTING_ERROR,
  error: true,
  payload: error,
});

export const getRecommendationDataRequest = () => ({
  type: GET_RECOMMENDATION_DATA_REQUEST,
});

export const getRecommendationDataSuccess = listing => ({
  type: GET_RECOMMENDATION_DATA_SUCCESS,
  payload: listing,
});

export const getRecommendationDataError = error => ({
  type: GET_RECOMMENDATION_DATA_ERROR,
  error: true,
  payload: error,
});

export const updateSearchedListings = listingRefs => ({
  type: UPDATE_SEARCHED_LISTINGS,
  payload: listingRefs,
});
// ================ Thunks ================ //

const getBounds = listingParams => {
  if (!listingParams.bounds || !listingParams.bounds.ne) return undefined;

  const { ne, sw } = listingParams.bounds;
  const { lat: nelat, lng: nelng } = ne;
  const { lat: swlat, lng: swlng } = sw;

  return `${nelat},${nelng},${swlat},${swlng}`;
};

const searchClassListings = listingParams => async (dispatch, getState, sdk) => {
  try {
    const defaultBounds = '1.4110994,103.9577613,1.2642103,103.6055395';
    const availability = 'time-partial';
    const pub_age_group = listingParams.pub_age_group;
    const pub_level_group = listingParams.pub_level_group;
    const pub_level_mode = listingParams.pub_level_mode;
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to midnight

    const end = new Date(start.getTime() + 24 * 60 * 60 * 1000);
    // const start = new Date();
    // const futureUtcTime = new Date(start.getTime() + 24 * 60 * 60 * 1000);
    // const end = futureUtcTime.toISOString();

    // const start = new Date();
    // const futureUtcTime = new Date(start.getTime() + 14 * 24 * 60 * 60 * 1000); // Add 14 days
    // const end = futureUtcTime.toISOString();

    const boundsString = getBounds(listingParams) || defaultBounds;

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },

      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          // 'per_page',
        ]),
        mapSearch: true,
        bounds: boundsString,
        availability: availability,
        pub_age_group: pub_age_group,
        pub_level_group: pub_level_group,
        pub_level_mode: pub_level_mode,
        start: start,
        end: end,
        'limit.images': 6,
        'page.limit': 50,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);
    if (response.data.data.length) {
      dispatch(addMarketplaceEntities(ensureResponse));
    }

    const listingRefs = response.data.data.map(
      ({ id, type, attributes }) =>
        !attributes?.metadata?.hidelisting && {
          id,
          type,
        }
    );
    dispatch(queryPromotedListingsSuccess(listingRefs));
    return ensureResponse;
  } catch (e) {
    console.log(e);
    dispatch(queryPromotedListingsError(storableError(e)));
    throw e;
  }
};

const searchClassListingsByDate = listingParams => async (dispatch, getState, sdk) => {
  try {
    const defaultBounds = '1.4110994,103.9577613,1.2642103,103.6055395';
    const availability = 'time-partial';
    const pub_age_group = listingParams.pub_age_group;
    const pub_level_group = listingParams.pub_level_group;
    const pub_level_mode = listingParams.pub_level_mode;
    const start = new Date();
    start.setHours(0, 0, 0, 0); // Set hours, minutes, seconds, and milliseconds to midnight

    const end = new Date(start.getTime() + 50 * 24 * 60 * 60 * 1000);
    // const start = new Date();
    // const futureUtcTime = new Date(start.getTime() + 24 * 60 * 60 * 1000);
    // const end = futureUtcTime.toISOString();

    // const start = new Date();
    // const futureUtcTime = new Date(start.getTime() + 14 * 24 * 60 * 60 * 1000); // Add 14 days
    // const end = futureUtcTime.toISOString();

    const boundsString = getBounds(listingParams) || defaultBounds;

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },

      params: {
        ...omit(listingParams, [
          'fields.listing',
          'fields.user',
          'fields.image',
          'include',
          // 'per_page',
        ]),
        mapSearch: true,
        bounds: boundsString,
        availability: availability,
        pub_age_group: pub_age_group,
        pub_level_group: pub_level_group,
        pub_level_mode: pub_level_mode,
        start: start,
        end: end,
        'limit.images': 6,
        'page.limit': 50,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);
    if (response.data.data.length) {
      dispatch(addMarketplaceEntities(ensureResponse));
    }

    const listingRefs = response.data.data.map(
      ({ id, type, attributes }) =>
        !attributes?.metadata?.hidelisting && {
          id,
          type,
        }
    );
    dispatch(queryPromotedListingsSuccess(listingRefs));
    return ensureResponse;
  } catch (e) {
    console.log(e);
    dispatch(queryPromotedListingsError(storableError(e)));
    throw e;
  }
};

export const companyFreelancerListings = () => (dispatch, getState, sdk) => {
  dispatch(companyFreelancerRequest());
  return sdk.listings
    .query({
      pub_listing_type: 'Company,company,freelancer',
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
        'variants.company-background-crop',
      ],
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    })
    .then(response => {
      const listingRefs = denormalisedResponseEntities(response);

      dispatch(companyFreelancerSuccess(listingRefs));

      return response;
    })
    .catch(e => dispatch(companyFreelancerError(storableError(e))));
};

export const queryPromotedListings = (searchquery = {}) => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  const availabilityParams = (datesParam, minDurationParam, timesParam) => {
    const dateValues = datesParam ? datesParam.split(',') : [];
    const hasDateValues = datesParam && dateValues.length === 2;
    const timeValues = timesParam ? timesParam.split(',') : [];
    const hasTimeValues = timeValues && timeValues.length === 2;
    const startDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[0]} ${timeValues[0]}`
        : hasDateValues
        ? dateValues[0]
        : null; //"2020-06-12 12:00"//;
    const endDate =
      hasDateValues && hasTimeValues
        ? `${dateValues[1]} ${timeValues[1]}`
        : hasDateValues
        ? dateValues[1]
        : null; //"2020-06-12 13:00"//;
    const minDurationMaybe =
      minDurationParam && Number.isInteger(minDurationParam) && hasDateValues
        ? { minDuration: minDurationParam }
        : {};
    const defaultTimeZone = () =>
      typeof window !== 'undefined'
        ? getDefaultTimeZoneOnBrowser()
        : config.custom.dateRangeLengthFilterConfig.searchTimeZone;
    const timeZone = defaultTimeZone();
    return hasDateValues
      ? {
          start: searchquery.start ? searchquery.start : formatDateStringToTz(startDate, timeZone),
          end: searchquery.end ? searchquery.end : formatDateStringToTz(endDate, timeZone),
          // When we have `time-partial` value in the availability, the
          // API returns listings that don't necessarily have the full
          // start->end range available, but enough that the minDuration
          // (in minutes) can be fulfilled.
          //
          // See: https://www.sharetribe.com/api-reference/marketplace.html#availability-filtering
          availability: 'time-partial',
          ...minDurationMaybe,
        }
      : {};
  };
  const priceSearchParams = priceParam => {
    const inSubunits = value =>
      convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
    const values = priceParam ? priceParam.split(',') : [];
    return priceParam && values.length === 2
      ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
      : {};
  };
  const getParams = () => {
    const {
      dates,
      minDuration,
      times,
      price,
      pub_has_premium_membership,
      pub_sub_category,
      pub_category,
      start,
      end,
      pub_kids_promotions,
      pub_listing_type,
      ...rest
    } = searchquery;

    const availabilityMaybe = availabilityParams(dates, minDuration, times);
    const priceMaybe = priceSearchParams(price);
    const subcategory = pub_sub_category
      ? { pub_sub_category: `has_any:${pub_sub_category}` }
      : { pub_sub_category: 'has_any:padel tennis' };
    const publicCategory = pub_category ? { pub_category: pub_category } : {};
    const hasPremiumMembership = pub_has_premium_membership
      ? { pub_has_premium_membership: pub_has_premium_membership }
      : {};
    const listingMaybe = !!pub_kids_promotions
      ? { pub_kids_promotions: pub_kids_promotions }
      : {
          pub_listing_type: searchquery['pub_listing_type'] ? searchquery?.pub_listing_type : null,
        };

    return {
      ...rest,
      ...availabilityMaybe,
      ...priceMaybe,
      ...subcategory,
      ...publicCategory,
      ...listingMaybe,
      ...hasPremiumMembership,
      mapSearch: true,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-small2x',
        'variants.scaled-medium',
        // Avatars
        'variants.square-small',
        'variants.square-small2x',
        'variants.company-background-crop',
      ],
      'imageVariant.company-background-crop': sdkUtil.objectQueryString({
        w: 1920,
        h: 473,
        fit: 'crop',
      }),
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    };
  };
  const params = getParams();
  if (
    params &&
    (params.pub_listing_type === LISTING_TYPES.CLASS ||
      params.pub_listing_type === LISTING_TYPES.FACILITY ||
      params.pub_listing_type === LISTING_TYPES.COURSES) &&
    !searchquery.searchByDate
  ) {
    return dispatch(searchClassListingsByDate(params));
  } else if (
    params &&
    (params.pub_listing_type === LISTING_TYPES.CLASS ||
      params.pub_listing_type === LISTING_TYPES.FACILITY ||
      params.pub_listing_type === LISTING_TYPES.COURSES) &&
    searchquery.searchByDate
  ) {
    return dispatch(searchClassListings(params));
  } else {
    return (
      params.pub_listing_type &&
      sdk.listings
        .query(params)
        .then(response => {
          // const listingRefs = response.data.data.filter(l => {
          //   const { id, type } = l;
          //   const hide = !!l?.attributes?.metadata?.hidelisting;
          //   if (!hide)
          //     return {
          //       id,
          //       type,
          //     };
          // });
          const listingRefs = response.data.data.filter(
            ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
          );
          // const listingCompanyRefs = response.data.data.map(({ id, type, relationships }) => ({
          //   id,
          //   type,
          //   authorId: relationships.author.data.id.uuid,
          // }));
          dispatch(addMarketplaceEntities(response));
          dispatch(queryPromotedListingsSuccess(listingRefs));
          dispatch(addPaginationData(response.data.meta));

          // dispatch(fetchAllCompanyReviews(listingCompanyRefs));
          // if (searchquery?.pub_listing_type === 'company') {
          //   dispatch(fetchAllCompanyReviews(listingCompanyRefs));
          // }
          if (!searchquery) {
            dispatch(clearFilter());
          }
          // Fetch reviews
          // response.data.data.map(({id}) => { dispatch(queryPromotedListingReviews(id.uuid)) })
          return response;
        })
        .catch(e => dispatch(queryPromotedListingsError(storableError(e))))
    );
  }
};

export const showListing = listingId => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  dispatch(clearFilter());
  return sdk.listings
    .show({
      id: listingId,
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
      'limit.images': 6,
      'page.limit': 50,
      sort: 'meta_promoted,createdAt',
    })
    .then(response => {
      const res = [response.data];
      const listingRefs = res.map(r => ({
        id: r.data.id,
        type: r.data.type,
      }));
      // const listingCompanyRefs = res.map(r => ({
      //   id: r.data.id,
      //   type: r.data.type,
      //   authorId: r.data.relationships.author.data.id.uuid,
      // }));
      dispatch(addMarketplaceEntities(response));
      dispatch(queryPromotedListingsSuccess(listingRefs));
      dispatch(addPaginationData({}));
      // dispatch(fetchAllCompanyReviews(listingCompanyRefs));

      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));
};

export const queryPromotedListingReviews = listingRef => (dispatch, getState, sdk) => {
  let listingReview = {};
  listingRef.forEach(async (ref, index) => {
    try {
      const resp = await sdk.reviews.query({
        listing_id: ref.id,
        state: 'public',
      });
      if (resp.data.data.length !== 0) {
        listingReview[ref.id.uuid] = resp.data.data;
      }
    } catch (error) {
      console.log(error);
    }
    if (index === listingRef.length - 1) {
      dispatch(allListingReviews(listingReview));
    }
  });
  // return sdk.reviews
  //   .query({
  //     listing_id: listingId,
  //     state: 'public',
  //     include: ['author', 'author.profileImage'],
  //     'fields.image': ['variants.square-small', 'variants.square-small2x'],
  //   }).then(response => {
  //     const reviews = denormalisedResponseEntities(response);
  //     dispatch(queryPromotedListingReviewsSuccess(reviews));
  //   })
  //   .catch(e => dispatch(queryPromotedListingReviewsError(e)));
};

export const updateListingStatus = (listingId, authorId) => async (dispatch, getState, sdk) => {
  const state = getState();
  const currentUserId = state.user.currentUser ? state.user.currentUser.id.uuid : '';
  if (currentUserId !== authorId) {
    return;
  }

  try {
    const params = {
      id: listingId,
      publicData: {
        last_active: moment().valueOf(),
      },
    };
    await sdk.ownListings.update(params);
  } catch (e) {
    console.log(e);
  }
};

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchAuthorListing = listingRef => async (dispatch, getState, sdk) => {
  let objAuthorListingsRef = {};
  const authorListings = getState().HomePage.authorListingsRefs;
  if (authorListings?.length) return;
  listingRef.forEach(async (ref, index) => {
    try {
      const authorListings = await sdk.listings.query({
        authorId: ref.uuid,
        pub_listing_type: 'company,Company,freelancer',
        include: ['author', 'author.profileImage', 'images'],
        'fields.image': [
          // Listing page
          'variants.landscape-crop',
          'variants.landscape-crop2x',
          'variants.landscape-crop4x',
          'variants.landscape-crop6x',

          // Social media
          'variants.facebook',
          'variants.twitter',

          // Image carousel
          'variants.scaled-small',
          'variants.scaled-medium',
          'variants.scaled-large',
          'variants.scaled-xlarge',

          // Avatars
          'variants.square-small',
          'variants.square-small2x',
        ],
      });
      const response = denormalisedResponseEntities(authorListings);
      objAuthorListingsRef[ref.uuid] = response;
      if (index === listingRef.length - 1) {
        dispatch(setAuthorsListingsRefs(objAuthorListingsRef));
      }
    } catch (e) {
      dispatch(showListingError(storableError(e)));
    }
  });
};

export const fetchAllCompanyReviews = listingRef => async (dispatch, getState, sdk) => {
  let listingReview = {};
  listingRef.forEach(async (ref, index) => {
    try {
      const listings = await sdk.listings.query({
        authorId: ref.authorId,
      });
      let arrReviews = [];
      listings.data.data.forEach(async (list, indexReview) => {
        const resp = await sdk.reviews.query({
          listing_id: list.id.uuid,
          state: 'public',
          include: ['author', 'author.profileImage'],
          'fields.image': ['variants.square-small', 'variants.square-small2x'],
        });

        const response = denormalisedResponseEntities(resp);
        if (response.length !== 0) arrReviews = [...arrReviews, ...response];
        if (indexReview === listings.data.data.length - 1) {
          listingReview[ref.id.uuid] = arrReviews;
        }

        if (index === listingRef.length - 1 && indexReview === listings.data.data.length - 1) {
          dispatch(allListingReviews(listingReview));
        }
      });
    } catch (error) {
      console.log(error);
    }
  });
  // return sdk.reviews
  //   .query({
  //     listing_id: listingId,
  //     state: 'public',
  //     include: ['author', 'author.profileImage'],
  //     'fields.image': ['variants.square-small', 'variants.square-small2x'],
  //   }).then(response => {
  //     const reviews = denormalisedResponseEntities(response);
  //     dispatch(queryPromotedListingReviewsSuccess(reviews));
  //   })
  //   .catch(e => dispatch(queryPromotedListingReviewsError(e)));
};

export const fetchAuthorClasses = (authorId, dates) => async (dispatch, getState, sdk) => {
  try {
    dispatch(queryPromotedListingsRequest());
    const { start, end } = dates;
    const startParams = !!start ? { start: start } : {};
    const endParams = !!end ? { end: end } : {};

    const response = await axios.get(config.serverBaseUrl + 'listings', {
      headers: {
        Authorization: `Token token=${config.serverToken}`,
      },
      params: {
        // authorId: authorId,
        pub_listing_type: 'class',
        availability: 'time-partial',
        ...startParams,
        ...endParams,
        include: 'author,author.profileImage,images',
        'fields.image':
          'variants.scaled-small,variants.square-small2x,variants.square-small,variants.square-small2x',
      },
      paramsSerializer: params => {
        return qs.stringify(params);
      },
    });
    const ensureResponse = ensureCURLListings(response);

    dispatch(addMarketplaceEntities(ensureResponse));
    dispatch(
      queryPromotedListingsSuccess(ensureResponse.data.data.map(({ id, type }) => ({ id, type })))
    );

    return ensureResponse;
  } catch (e) {
    dispatch(fetchAuthorClassesError(storableError(e)));
    throw e;
  }
};

export const onSearchEnquiry = searchParams => (dispatch, getState, sdk) => {
  dispatch(queryPromotedListingsRequest());
  const subcategoryAlt = searchParams.pub_sub_category
    ? { pub_sub_category: `has_any:${searchParams.pub_sub_category.join(',')}` }
    : {};
  const params = {
    ...searchParams,
    // ...subcategory,
    mapSearch: true,
    include: ['author', 'author.profileImage', 'images'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',
      // Avatars
      'variants.square-small',
      'variants.square-small2x',
      'variants.company-background-crop',
    ],
    'imageVariant.company-background-crop': sdkUtil.objectQueryString({
      w: 1920,
      h: 473,
      fit: 'crop',
    }),
    'limit.images': 6,
    'page.limit': 50,
    sort: 'meta_promoted,createdAt',
  };

  const listing = [];

  sdk.listings
    .query({ ...params })
    .then(response => {
      // const listingRefs = response.data.data.filter(l => {
      //   const { id, type } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       type,
      //     };
      // });
      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
      );
      listing.push(...listingRefs);
      dispatch(addMarketplaceEntities(response));
      return response;
    })
    .catch(e => dispatch(queryPromotedListingsError(storableError(e))));

  searchParams.pub_sub_category &&
    sdk.listings.query({ ...params, ...subcategoryAlt }).then(response => {
      // const listingRefs = response.data.data.filter(l => {
      //   const { id, type } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       type,
      //     };
      // });
      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) => !attributes?.metadata?.hidelisting && { id, type }
      );
      listing.push(...listingRefs);

      dispatch(addMarketplaceEntities(response));
      // dispatch(queryPromotedListingsSuccess(listingRefs));
      let res = listingRefs.filter((v, i, a) => a.findIndex(v2 => v2.id.uuid === v.id.uuid) === i);
      dispatch(queryPromotedListingsSuccess(res));
      return response;
    });
};

export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query(searchParams)
    .then(response => {
      // const listingRefs = response.data.data.filter(l => {
      //   const { id } = l;
      //   const hide = !!l?.attributes?.metadata?.hidelisting;
      //   if (!hide)
      //     return {
      //       id,
      //       title: l.attributes.title,
      //     };
      // });
      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) =>
          !attributes?.metadata?.hidelisting && { id, type, title: attributes.title }
      );

      dispatch(addMarketplaceEntities(response));
      dispatch(searchListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const fetchCompanies = () => async (dispatch, getState, sdk) => {
  try {
    const response = await sdk.listings.query({
      pub_listing_type: 'company',
      include: ['author', 'author.profileImage', 'images'],
      'fields.image': [
        'variants.scaled-small',
        'variants.square-small2x',

        // Avatars
        'variants.square-small',
        'variants.square-small2x',
      ],
    });

    const refs = entityRefs(response.data.data);
    dispatch(addMarketplaceEntities(response));
    dispatch(fetchCompaniesSuccess(refs));
  } catch (e) {
    console.error(e);
    dispatch(fetchCompaniesError(storableError(e)));
  }
};

//for enquiry
export const sendEnquiry = (listingId, message) => (dispatch, getState, sdk) => {
  dispatch(sendEnquiryRequest());
  const bodyParams = {
    transition: TRANSITION_ENQUIRE,
    processAlias: config.bookingProcessAlias,
    params: { listingId },
  };

  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(res => {
        dispatch(sendEnquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendEnquiryError(storableError(e)));
      throw e;
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  const currentState = getState().HeaderSection;
  if (
    currentState.searchLocation?.search ||
    currentState.searchActivity ||
    currentState.searchSelectedRating
  )
    return Promise.resolve();

  dispatch(setInitialState());
  dispatch(clearFilter());
  const listingType = currentState?.searchListingTab;

  return Promise.all([dispatch(queryPromotedListings({ pub_listing_type: listingType }))]);
  // return Promise.all([dispatch(queryPromotedListings()), dispatch(fetchCompanies())]);
};

export const createMatchActivityListing = (data, has_login) => {
  return async (dispatch, getState, sdk) => {
    dispatch(matchActivityListingsRequest());

    return sdk.ownListings
      .create(data)
      .then(response => {
        const matchActivityRes = response.data;
        dispatch(matchActivityListingsSuccess());
        return matchActivityRes;
      })
      .catch(error => {
        console.log(error);
        dispatch(matchActivityListingsError(error));
      });
  };
};

export const updateClientListing = (listingId, coachId, startDate) => async (
  dispatch,
  getState,
  sdk
) => {
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });

  // Step 1: Fetch user's existing listing
  const res = await sdk.listings.show({
    id: listingId,
    include: ['author', 'author.profileImage'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  });

  const userId = res.data.included[1].id;
  const userListingResponse = await sdk.users.show({ id: userId });
  const userListing = userListingResponse.data?.data;

  // Step 2: Get the existing recommended JSON or create a new one
  const existingPublicData = userListing.attributes?.profile?.publicData || {};
  const existingRecommendedJson = existingPublicData.recommendedBy || [];

  // Step 3: Check if the user already has an entry
  if (existingRecommendedJson.length > 0) {
    // If an entry already exists, you may want to handle this case accordingly
    console.log('User already has a recommended entry.');
    return;
  }

  // Step 4: If not, create a new entry and update the user's listing
  const newEntry = {
    coachId: coachId,
    startDate: startDate,
  };

  const updatedRecommendedJson = [newEntry]; // Only one entry allowed

  // Step 5: Update the user's listing with the new recommended JSON
  await integrationSdk.users.updateProfile({
    id: userId,
    publicData: {
      ...existingPublicData,
      recommendedBy: updatedRecommendedJson,
    },
  });

  return null;
};

export const updateRecommenderListing = (
  email,
  name,
  currentUserId,
  startDate,
  listingId
) => async (dispatch, getState, sdk) => {
  const res = await sdk.listings.show({
    id: listingId,
    include: ['author', 'author.profileImage'],
    'fields.image': [
      'variants.scaled-small',
      'variants.square-small2x',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
  });

  const userImage = res.data?.included[0]?.attributes?.variants['square-small']?.url;
  const userId = res.data.included[1].id.uuid;
  // Step 1: Fetch user's existing listing
  const userListingResponse = await sdk.users.show({ id: currentUserId });
  const userListing = userListingResponse.data?.data;

  // Step 2: Get the existing recommended JSON or create a new one
  const existingPublicData = userListing.attributes?.profile?.publicData || {};
  const existingRecommendedJson = existingPublicData.recommendedClients || [];

  // Step 3: Check if there is already an entry with the same url
  const entryWithSameClientId = existingRecommendedJson.find(entry => entry.userId === userId);

  // Step 4: If there is an existing entry, update it; otherwise, create a new entry
  let updatedRecommendedJson;

  if (!entryWithSameClientId) {
    // If an entry with the same url already exists, keep the old entries and add a new one
    updatedRecommendedJson = [
      ...existingRecommendedJson,
      {
        clientImage: userImage,
        clientId: userId,
        clientName: name,
        clientEmail: email,
        startDate: startDate,
        requestedAmount: 0,
        status: 'NA',
      },
    ];
  } else {
    // If there is no existing entry, create a new one
    const newEntry = {
      clientImage: userImage,
      clientId: userId,
      clientName: name,
      clientEmail: email,
      startDate: startDate,
      requestedAmount: 0,
      status: 'NA',
    };

    updatedRecommendedJson = [...existingRecommendedJson, newEntry];
  }

  // Step 5: Update the user's listing with the new recommended JSON
  await sdk.currentUser.updateProfile(
    {
      publicData: {
        ...existingPublicData,
        recommendedClients: updatedRecommendedJson,
      },
    },
    {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    }
  );
};

export const updateAdminProfile = (currentUserId, startDate, listingId) => async (
  dispatch,
  getState,
  sdk
) => {
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });

  const res = await sdk.listings.show({
    id: listingId,
    include: ['author'],
  });
  const userId = res.data.included[0].id.uuid;
  // Step 1: Fetch user's existing listing
  const userListingResponse = await sdk.users.show({ id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2' });
  const userListing = userListingResponse.data?.data;

  // Step 2: Get the existing recommended JSON or create a new one
  const existingPublicData = userListing.attributes?.profile?.publicData || {};
  const existingClientListJson = existingPublicData.clientList || [];

  const entryWithSameClientId = existingClientListJson.find(entry => entry.userId === userId);

  // Step 4: If there is an existing entry, update it; otherwise, create a new entry
  let updatedClientListJson;

  if (!entryWithSameClientId) {
    // If an entry with the same url already exists, keep the old entries and add a new one
    updatedClientListJson = [
      ...existingClientListJson,
      {
        clientId: userId,
        coachId: currentUserId,
        startDate: startDate,
        haveToPay: 0,
        status: 'NA',
      },
    ];
  } else {
    // If there is no existing entry, create a new one
    const newEntry = {
      clientId: userId,
      coachId: currentUserId,
      startDate: startDate,
      haveToPay: 0,
      status: 'NA',
    };

    updatedClientListJson = [...existingClientListJson, newEntry];
  }

  // Step 5: Update the user's listing with the new recommended JSON
  await integrationSdk.users.updateProfile(
    {
      id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2',
      publicData: {
        ...existingPublicData,
        clientList: updatedClientListJson,
      },
    },
    {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    }
  );
};

export const getRecommendationData = () => (dispatch, getState, sdk) => {
  dispatch(getRecommendationDataRequest());

  return sdk.currentUser
    .show()
    .then(response => {
      const data = response.data;
      const recommendedClients =
        data?.data?.attributes?.profile?.publicData?.recommendedClients || [];

      // Map the data as needed
      const listingRes = recommendedClients.map(
        ({
          clientId,
          clientEmail,
          clientName,
          startDate,
          clientImage,
          requestedAmount,
          status,
        }) => ({
          clientId: clientId,
          clientEmail: clientEmail,
          clientName: clientName,
          startDate: startDate,
          clientImage: clientImage,
          requestedAmount: requestedAmount,
          status: status,
        })
      );

      dispatch(getRecommendationDataSuccess({ listingRes }));
      return response;
    })
    .catch(e => {
      console.log(e);
      dispatch(getRecommendationDataError(e));
      return e;
    });
};

export const getClientTransactions = (clientId, startDate, formattedPresentDate) => (
  dispatch,
  getState,
  sdk
) => {
  return new Promise((resolve, reject) => {
    sdk.transactions
      .query({
        userId: clientId,
        createdAtStart: startDate,
        createdAtEnd: formattedPresentDate,
      })
      .then(response => {
        const data = response.data.data;

        // Filter transactions based on the condition
        const filteredData = data.map(transaction => {
          return transaction.attributes.lastTransition === 'transition/confirm-payment';
        });

        // Count of transactions that satisfy the condition
        const count = filteredData.filter(value => value === true).length;

        // Get payinTotal only for transactions where filteredData is true
        const filteredPayinTotal = data
          .filter((_, index) => filteredData[index])
          .map(transaction => transaction.attributes.payinTotal);

        // Calculate the sum of payinTotal amounts
        const totalPayinAmount = filteredPayinTotal.reduce((sum, payinTotal) => {
          return sum + (payinTotal ? payinTotal.amount : 0);
        }, 0);

        resolve({ count, totalPayinAmount }); // Resolving the promise with the count value
      })
      .catch(error => {
        reject(error); // Rejecting the promise with the error
      });
  });
};

export const onRewardRequestAdmin = (clientId, tenPercent, formattedPresentDate) => async (
  dispatch,
  getState,
  sdk
) => {
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });

  const rewardReq = await integrationSdk.users
    .show({ id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2' })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.profile.publicData.clientList;
    })
    .catch(e => {
      console.log(e);
    });

  if (rewardReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = rewardReq.map(item => {
      if (item.clientId === clientId) {
        return {
          ...item,
          haveToPay: tenPercent,
          status: 'pending',
          startDate: formattedPresentDate,
        };
      }
      return item;
    });

    await integrationSdk.users.updateProfile(
      {
        id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2',
        publicData: {
          clientList: updatedJoinReq,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    );
  }
};

export const onRewardRequestRecommender = (
  currentUserId,
  clientId,
  tenPercent,
  formattedPresentDate
) => async (dispatch, getState, sdk) => {
  const rewardReq = await sdk.users
    .show({ id: currentUserId })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.profile.publicData.recommendedClients;
    })
    .catch(e => {
      console.log(e);
    });

  if (rewardReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = rewardReq.map(item => {
      if (item.clientId === clientId) {
        return {
          ...item,
          requestedAmount: tenPercent,
          status: 'pending',
          startDate: formattedPresentDate,
        };
      }
      return item;
    });

    await sdk.currentUser.updateProfile(
      {
        publicData: {
          recommendedClients: updatedJoinReq,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    );
  }
};

export const onAcceptRequestAdmin = clientId => async (dispatch, getState, sdk) => {
  const integrationSdk = await flexIntegrationSdk.createInstance({
    clientId: process.env.REACT_APP_INTEGRATION_CLIENT_ID,
    clientSecret: process.env.REACT_APP_INTEGRATION_CLIENT_SECRET,
    // baseUrl:
    //   process.env.REACT_APP_FLEX_INTEGRATION_BASE_URL || 'https://flex-integ-api.sharetribe.com',
  });

  const rewardReq = await integrationSdk.users
    .show({ id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2' })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.profile.publicData.clientList;
    })
    .catch(e => {
      console.log(e);
    });

  if (rewardReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = rewardReq.map(item => {
      if (item.clientId === clientId) {
        return {
          ...item,
          haveToPay: 0,
          status: 'NA',
        };
      }
      return item;
    });

    await integrationSdk.users.updateProfile(
      {
        id: '642bc0c2-57ed-42b5-a65e-1917cb969ba2',
        publicData: {
          clientList: updatedJoinReq,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    );
  }
};

export const onAcceptRequestRecommender = (currentUserId, clientId) => async (
  dispatch,
  getState,
  sdk
) => {
  const rewardReq = await sdk.users
    .show({ id: currentUserId })
    .then(response => {
      const listingRes = response.data?.data;
      return listingRes.attributes.profile.publicData.recommendedClients;
    })
    .catch(e => {
      console.log(e);
    });

  if (rewardReq) {
    // Find the joinRequest item with the specified userId
    const updatedJoinReq = rewardReq.map(item => {
      if (item.clientId === clientId) {
        return {
          ...item,
          requestedAmount: 0,
          status: 'NA',
        };
      }
      return item;
    });

    await sdk.currentUser.updateProfile(
      {
        publicData: {
          recommendedClients: updatedJoinReq,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    );
  }
};
