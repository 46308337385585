import React, { useEffect, useState } from 'react';
import moment from 'moment-timezone';
import FacilitiesInlineListingCard from './FacilitiesInlineListingCard';
import { Button, DatePicker, Input, Select, Spin } from 'antd';
import css from './FacilitiesComponent.css';
import { searchListings } from 'containers/HeaderContainer/HeaderContainer.duck';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { formatDateStringToTz, getDefaultTimeZoneOnBrowser } from 'util/dates';
import config from '../../config';

const generateTimeOptions = () => {
  const options = [];
  for (let i = 12; i <= 47; i++) {
    const halfHoursFrom6 = i - 12;
    const hours = Math.floor(halfHoursFrom6 / 2) + 6;
    const minutes = halfHoursFrom6 % 2 === 0 ? '00' : '30';
    if (hours < 24) {
      const timeString = `${hours.toString().padStart(2, '0')}:${minutes}`;
      const dateObject = new Date();
      dateObject.setHours(hours, minutes, 0, 0);
      options.push({ value: dateObject.toISOString(), label: timeString });
    }
  }
  return options;
};

const timeOptions = generateTimeOptions();

const FacilitiesComponent = props => {
  const { className, listings, allClassListingReviews, showAll } = props;
  const dispatch = useDispatch();
  const searchInProgress = useSelector(state => state.HeaderSection.searchInProgress);

  const [searchValues, setSearchValues] = useState({
    sport: 'padel tennis',
    date: dayjs(),
    time: timeOptions[0].value,
  });

  useEffect(() => {
    console.log('Listings updated:', listings);
  }, [listings]);

  const defaultTimeZone = () => {
    return typeof window !== 'undefined'
      ? getDefaultTimeZoneOnBrowser()
      : config.custom.dateRangeLengthFilterConfig.searchTimeZone || 'Europe/London';
  };

  const handleSearch = () => {
    const { date, time } = searchValues;

    const selectedDateTime = moment(
      `${dayjs(date).format('YYYY-MM-DD')} ${dayjs(time).format('HH:mm')}`
    );
    const timeZone = defaultTimeZone();

    const startDate = selectedDateTime.toISOString(true);
    const endDate = selectedDateTime
      .clone()
      .add(2, 'hours')
      .toISOString(true);

    const searchParams = {
      pub_sub_category: searchValues.sport,
      start: formatDateStringToTz(startDate, timeZone),
      end: formatDateStringToTz(endDate, timeZone),
      availability: 'time-partial',
      minDuration: 30,
      address: 'Singapore',
      bounds: '1.4110994,103.9577613,1.2642103,103.6055395',
      pub_listing_type: 'facility',
    };

    dispatch(searchListings(searchParams));
  };

  const handleChange = (field, value) => {
    setSearchValues(prev => ({ ...prev, [field]: value }));
  };

  const handleTodayClick = () => {
    handleChange('date', dayjs());
  };

  const handleTomorrowClick = () => {
    handleChange('date', dayjs().add(1, 'day'));
  };

  const getCloseThreeTimes = (selectedTime) => {
    if (!selectedTime) {
      return [];
    }

    const selectedMoment = moment(selectedTime);
    const timesWithDifference = timeOptions.map(option => {
      const optionMoment = moment(option.value);
      const difference = Math.abs(optionMoment.diff(selectedMoment, 'minutes'));
      return { ...option, difference };
    });

    timesWithDifference.sort((a, b) => a.difference - b.difference);

    return timesWithDifference.slice(0, 3).map(item => item.label);
  };

  return (
    <div>
      <div className={css.outerDateSelection}>
        <Select
          className={css.outerDateSelectionSelect}
          value={searchValues.sport}
          onChange={value => handleChange('sport', value)}
          options={[
            { value: 'padel tennis', label: 'Padel' },
            { value: 'tennis', label: 'Tennis' },
            { value: 'pickle ball', label: 'Pickleball' },
          ]}
        />
        <DatePicker
          className={css.outerDateSelectionDatePicker}
          value={searchValues.date}
          onChange={date => handleChange('date', date)}
          panelRender={panelNode => (
            <div className={css.outerDateSelectionDatePickerSelector}>
              <div className={css.datePickerSelectorButtonWrap}>
                <Button className={css.datePickerSelectorButton} onClick={handleTodayClick}>
                  Today
                </Button>
                <Button className={css.datePickerSelectorButton} onClick={handleTomorrowClick}>
                  Tomorrow
                </Button>
              </div>
              {panelNode}
            </div>
          )}
        />
        <Select
          className={css.outerDateSelectionSelect}
          value={searchValues.time}
          onChange={value => handleChange('time', value)}
          options={timeOptions}
        />
        <div className={css.searchIcon} onClick={handleSearch}>
          {searchInProgress ? (
            <Spin size='small' />
          ) : (
            <svg fill='#000000' height='14px' width='14px' viewBox='0 0 488.4 488.4'>
              <path d='M0,203.25c0,112.1,91.2,203.2,203.2,203.2c51.6,0,98.8-19.4,134.7-51.2l129.5,129.5c2.4,2.4,5.5,3.6,8.7,3.6 s6.3-1.2,8.7-3.6c4.8-4.8,4.8-12.5,0-17.3l-129.6-129.5c31.8-35.9,51.2-83,51.2-134.7c0-112.1-91.2-203.2-203.2-203.2 S0,91.15,0,203.25z M381.9,203.25c0,98.5-80.2,178.7-178.7,178.7s-178.7-80.2-178.7-178.7s80.2-178.7,178.7-178.7 S381.9,104.65,381.9,203.25z' />
            </svg>
          )}
          Search
        </div>
      </div>

      {searchInProgress ? (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '40px' }}>
          <Spin size='large' tip='Searching facilities...' />
        </div>
      ) : listings.length > 0 ? (
        listings.map(l => {
          const closeTimes = getCloseThreeTimes(searchValues.time);
          return (
            <FacilitiesInlineListingCard
              className={className}
              key={l.id.uuid}
              listing={l}
              review={allClassListingReviews ? allClassListingReviews[l.id.uuid] : []}
              selectedDate={searchValues.date}
              showAll={showAll}
              closeTimes={closeTimes}
            />
          );
        })
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            padding: '40px',
            fontSize: '16px',
            color: '#777',
          }}
        >
          No availability for applied filters
        </div>
      )}
    </div>
  );
};

export default FacilitiesComponent;