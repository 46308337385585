import { fetchCurrentUser } from 'ducks/user.duck';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { updateSearchedListings } from 'containers/HomePage/HomePage.duck';

// ================ Action types ================ //
export const SET_INITIAL_STATE = 'app/HeaderSection/SET_INITIAL_STATE';

export const SEARCH_LISTINGS_REQUEST = 'app/HeaderSection/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/HeaderSection/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/HeaderSection/SEARCH_LISTINGS_ERROR';

export const ON_SEARCH_FILTER = 'app/HeaderSection/ON_SEARCH_FILTER';

export const ON_CLEAR_SEARCH_FILTER = 'app/HeaderSection/ON_CLEAR_SEARCH_FILTER';

export const ON_SEARCH_SUBCATEGORY = 'app/HeaderSection/ON_SEARCH_SUBCATEGORY';

export const ON_SEARCH_LOCATION = 'app/HeaderSection/ON_SEARCH_LOCATION';

export const ON_SEARCH_LISTING_TAB = 'app/HeaderSection/ON_SEARCH_LISTING_TAB';

export const ON_SEARCH_Category_LOCATION = 'app/HeaderSection/ON_SEARCH_Category_LOCATION';

// ================ Reducer ================ //

const initialState = {
  suggestedListings: [],
  searchListingsError: null,
  searchActivity: null,
  searchLocation: null,
  searchListingTab: null,
  searchSelectedListing: null,
  searchCategoryLocation: null,
  searchInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_STATE:
      return { ...initialState };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        suggestedListings: state.suggestedListings,
        searchListingsError: null,
        searchInProgress:true
      };
    case ON_SEARCH_FILTER:
      return {
        ...state,
        searchActivity: payload.searchActivity,
        searchLocation: payload.searchLocation || {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        },
        pub_listing_type: payload.selectedListing,
      };

    case ON_CLEAR_SEARCH_FILTER:
      return {
        ...state,
        searchActivity: null,
        searchLocation: {
          predictions: [],
          search: '',
          selectedPlace: {
            address: '',
          },
        },
        searchSelectedListing: null,
        searchListingTab: null,
      };
    case ON_SEARCH_SUBCATEGORY:
      return {
        ...state,
        searchActivity: payload,
      };
    case ON_SEARCH_LOCATION:
      return {
        ...state,
        searchLocation: payload,
      };
    case ON_SEARCH_LISTING_TAB:
      return {
        ...state,
        searchListingTab: payload,
        searchSelectedListing: payload,
      };
    case ON_SEARCH_Category_LOCATION:
      return {
        ...state,
        searchCategoryLocation: payload,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return { ...state, suggestedListings: payload.listings, searchInProgress: false };
    case SEARCH_LISTINGS_ERROR:
      return { ...state, suggestedListings: [], searchListingsError: payload, searchInProgress: false };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const setInitialState = () => ({
  type: SET_INITIAL_STATE,
});

export const searchListingsRequest = () => ({
  type: SEARCH_LISTINGS_REQUEST,
});

export const searchListingsSuccess = listings => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { listings },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const onSearchFilter = searchFilters => ({ type: ON_SEARCH_FILTER, payload: searchFilters });

export const onClearSearchFilter = () => ({ type: ON_CLEAR_SEARCH_FILTER });

export const onSearchSubCategory = searchSubCategory => ({
  type: ON_SEARCH_SUBCATEGORY,
  payload: searchSubCategory,
});

export const onSearchLocation = searchlocation => ({
  type: ON_SEARCH_LOCATION,
  payload: searchlocation,
});

export const onSearchListingTab = searchListing => ({
  type: ON_SEARCH_LISTING_TAB,
  payload: searchListing,
});

export const searchCategoryLocation = searchCategoryLocation => ({
  type: ON_SEARCH_Category_LOCATION,
  payload: searchCategoryLocation,
});

// ================ Thunks ================ //
export const searchListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest());
  return sdk.listings
    .query({
      ...searchParams,
      mapSearch: true,
      include: ['images'],
      'fields.image': ['variants.scaled-small', 'variants.scaled-small2x'],
    })
    .then(response => {
      const listingRefs = response.data.data.filter(
        ({ id, type, attributes }) =>
          !attributes?.metadata?.hidelisting && { id, type, title: attributes.title }
      );
      dispatch(addMarketplaceEntities(response));
      dispatch(updateSearchedListings(listingRefs));
      dispatch(searchListingsSuccess(listingRefs));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(e));
      throw e;
    });
};

export const addingSellerModeUserData = (mode, currentUser) => (dispatch, getState, sdk) => {
  if (!currentUser) return;
  return sdk.currentUser
    .updateProfile(
      {
        publicData: {
          sellerMode: mode,
        },
      },
      {
        expand: true,
        include: ['profileImage'],
        'fields.image': ['variants.square-small', 'variants.square-small2x'],
      }
    )
    .then(() => {
      // res.data
      dispatch(fetchCurrentUser());
    })
    .catch(e => {
      console.error('---->', e);
    });
};

export const loadData = () => (dispatch, getState, sdk) => {
  // Clear state so that previously loaded data is not visible
  // in case this page load fails.
  dispatch(setInitialState());

  return;
  //return Promise.all([dispatch(queryPromotedListings())]);
};
